export enum EnumWebsiteModules {
  Menu = 1,
  Website = 2,
  OnlineOrder = 4,
  Catering = 8,
  Blog = 16,
  Delivery = 32,
  DineIn = 64,
  Discounts = 128,
  ProductDiscountGenerator = 256,
  Careers = 512
}
