import {Component, Input, OnInit} from '@angular/core';
import {getDay} from 'date-fns';
import {LocationModel} from "../../models/LocationModel";
import {HoursAvailabilityModel} from "../../models/availability/HoursAvailabilityModel";
import {BehaviorSubject} from "rxjs";
import {EnumDays} from "../../models/enums/EnumDays";
import {LocationInfoDialogComponent} from "../../dialogs/location-info-dialog/location-info-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {EnumWebsiteModules} from "../../models/enums/EnumWebsiteModules";

@Component({
  selector: 'app-location-availability',
  templateUrl: './location-availability.component.html',
  styleUrls: ['./location-availability.component.scss']
})
export class LocationAvailabilityComponent implements OnInit {
  @Input() location!: Nullable<LocationModel>;
  @Input() showMoreInfo: boolean = false;
  private bsIsOpen = new BehaviorSubject<boolean>(false);
  public isOpen$ = this.bsIsOpen.asObservable();
  private bsHours = new BehaviorSubject<HoursAvailabilityModel>(new HoursAvailabilityModel({day: this.getTodayAsEnum()}));
  public hours$ = this.bsHours.asObservable();

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.processToday();
  }

  openMoreInfo() {
    this.dialog.open(LocationInfoDialogComponent, {
      data: this.location,
      width: '470px'
    });
  }

  getTime(isEnd: boolean) {

    if (this.location == null)
      return '';
    const moduleAvailability = this.location.availabilities.getAvailability(EnumWebsiteModules.OnlineOrder);
    if (moduleAvailability == null)
      return '';

    const dtNow = new Date();
    return moduleAvailability.getTime(dtNow, isEnd);
  }

  private processToday() {
    const dtNow = new Date();
    const closedToday = new HoursAvailabilityModel({isOpen: false});
    if (this.location == null) {
      this.bsIsOpen.next(false);
      this.bsHours.next(closedToday);
      return;
    } else
      this.bsIsOpen.next(this.location.isOpen(EnumWebsiteModules.OnlineOrder));

    const moduleAvailability = this.location.availabilities.getAvailability(EnumWebsiteModules.OnlineOrder);
    if (moduleAvailability == null) {
      this.bsIsOpen.next(false);
      this.bsHours.next(closedToday);
    } else {
      this.bsIsOpen.next(moduleAvailability.isOpen(dtNow))
      this.bsHours.next(moduleAvailability.getToday());
    }
  }

  private getTodayAsEnum(): EnumDays {
    const dayOfWeek = getDay(new Date()); // 0 = Sunday, 1 = Monday etc.

    switch (dayOfWeek) {
      case 0:
        return EnumDays.Sunday;
      case 1:
        return EnumDays.Monday;
      case 2:
        return EnumDays.Tuesday;
      case 3:
        return EnumDays.Wednesday;
      case 4:
        return EnumDays.Thursday;
      case 5:
        return EnumDays.Friday;
      default:
        return EnumDays.Saturday;
    }
  }
}
