import {OrderItemPriceModel} from "../OrderItemPriceModel";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../../utils/Utils";

export class OrderCartItemModifierModel {
  public groupToken: Nullable<string> = null;
  public itemToken: Nullable<string> = null;
  public price: OrderItemPriceModel = new OrderItemPriceModel();
  public quantity: number = 1;
  public name: string = '';
  public modifiers: OrderCartItemModifierModel[] = [];

  // public totalPrice: number = 0;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.name)) {
      this.name = data.name;
    }
    if (isStringNotEmptyGuid(data.groupToken))
      this.groupToken = data.groupToken;
    if (isStringNotEmptyGuid(data.itemToken))
      this.itemToken = data.itemToken;
    if (data.price != null)
      this.price = new OrderItemPriceModel(data.price);
    if (data.quantity != null && !isNaN(parseInt(data.quantity, 10)))
      this.quantity = parseInt(data.quantity, 10);
  }

  public getTotal(): number {
    let total = 0;

    if (this.quantity == 0)
      return 0;
    if (this.price == null || this.price.price == null)
      return 0;

    total = this.quantity * this.price.price;
    if (this.modifiers.length > 0)
    {
      for (let modifier of this.modifiers) {
        total += modifier.getTotal();
      }
    }

    return total;
  }
}
