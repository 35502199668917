import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class CartLocalStorageModel {
  public id: string = '';

  public items: { [locationToken: string]: Nullable<string> } = {};
  public selectedLocationId: Nullable<string> = null;

  constructor(data: any = null) {
    if (data == null)
      return;
    if (isStringNotEmptyGuid(data.id))
      this.id = data.id;

    if (data.items != null)
      for (let key of Object.keys(data.items))
        this.items[key] = data.items[key];
    if (isStringNotEmptyOrWhitespace(data.selectedLocationId))
      this.selectedLocationId = data.selectedLocationId;
  }
}

