import {OrderItemPriceSizeModel} from "./OrderItemPriceSizeModel";
import {formatCurrency} from "@angular/common";
import {BaseModelInt} from "./BaseModelInt";

export class OrderItemPriceModel extends BaseModelInt {

  public price: Nullable<number> = null;
  public sizePrices: OrderItemPriceSizeModel[] = [];

  constructor(data: any = null) {
    super(data);
    if (data == null)
      return;

    if (data.id != null && !isNaN(parseInt(data.id, 10)))
      this.id = parseInt(data.id, 10);
    if (data.price != null && !isNaN(parseFloat(data.price)))
      this.price = parseFloat(data.price);
    if (data.sizePrices != null && Array.isArray(data.sizePrices) && data.sizePrices.length > 0)
      for (let item of data.sizePrices) this.sizePrices.push(new OrderItemPriceSizeModel(item));
  }

  public getPrice(currency = '$') {
    return this.price == null || this.price == 0 ? 'FREE' : formatCurrency(this.price, 'en-US', currency);
  }
}
