import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {LocationModel} from "../models/LocationModel";
import {WebsiteService} from "./website.service";
import {WebsiteModel} from "../models/WebsiteModel";
import {LocalStorageService} from "./local-storage.service";
import {isStringNotEmptyGuid} from "../utils/Utils";

@Injectable({
  providedIn: 'root'
})
export class LocationService implements OnDestroy {
  public website$: Observable<WebsiteModel>;
  private bsSelectedLocation = new BehaviorSubject<LocationModel>(new LocationModel());
  public location$ = this.bsSelectedLocation.asObservable();
  private sub = new Subscription();

  constructor(private webSvc: WebsiteService, private lsSvc: LocalStorageService) {
    this.website$ = this.webSvc.website$;
    this.init();
  }

  selectLocation(item: LocationModel) {
    this.bsSelectedLocation.next(item);
    if (isStringNotEmptyGuid(item.id))
      this.lsSvc.saveLocation(item.id);
  }

  public getCurrentLocation() {
    return this.bsSelectedLocation.value;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private init() {
    this.sub.add(
      this.website$.subscribe(data => {
        if (data != null && isStringNotEmptyGuid(data.id) && data.locations?.length > 0) {
          const lsO = this.lsSvc.getCurrentLS();
          if (lsO != null && isStringNotEmptyGuid(lsO.selectedLocationId)) {
            const loc = data.locations.find(f => f.id === lsO.selectedLocationId);
            if (loc != null) {
              this.bsSelectedLocation.next(loc);
              return;
            }
          }
          this.bsSelectedLocation.next(data.locations[0]);
        }
      })
    );
  }
}
