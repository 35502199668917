import {Injectable} from '@angular/core';
import {SessionStorageService} from "./session-storage.service";
import {HttpService} from "./base/http.service";
import {WebsiteService} from "./website.service";
import {OrdersAuthResponseModel} from "../models/auth/OrdersAuthResponseModel";
import {OrdersAuthRequestModel, OtpAuthRequestModel} from "../models/auth/OrdersAuthRequestModel";
import {map} from "rxjs/operators";
import {BehaviorSubject, Observable} from "rxjs";
import {EnumAuthResponseType} from "../models/auth/EnumAuthResponseType";
import {OtpAuthUserBaseDataModel} from "../models/auth/OtpAuthUserBaseDataModel";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiEndpoints} from "../utils/ApiEndpoints";
import {CartService} from "./cart.service";
import {LocationService} from "./location.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private bsStep = new BehaviorSubject<number>(0);
  public currentStep$ = this.bsStep.asObservable();

  private bsUserData = new BehaviorSubject<OtpAuthUserBaseDataModel>(new OtpAuthUserBaseDataModel());
  public currentUserData$ = this.bsUserData.asObservable();

  private bsAuthResponses = new BehaviorSubject<OrdersAuthResponseModel>(new OrdersAuthResponseModel());
  public currentAuthResponse$ = this.bsAuthResponses.asObservable();

  constructor(private sessionSvc: SessionStorageService, private http: HttpService, private webSvc: WebsiteService, private snackBar: MatSnackBar, private cartSvc: CartService, private locSvc: LocationService) {
    this.init();
  }

  public getUserData() {
    return this.bsUserData.value;
  }

  public authEmail(email: string): Observable<OrdersAuthResponseModel> {
    const web = this.webSvc.getCurrentWebsite();
    const request = new OrdersAuthRequestModel({email: email, isEmail: true});

    const retObs = this.http.post<OrdersAuthResponseModel>(`${ApiEndpoints.authEmail}::${web.id}`, request)
      .pipe(map(f => new OrdersAuthResponseModel(f)));
    retObs.subscribe(data => {
      // console.log(data);
      switch (data.responseType) {
        case EnumAuthResponseType.Ok:
          this.bsAuthResponses.next(data);
          this.bsStep.next(1);
          break;
        case EnumAuthResponseType.UserNotFound:
          this.bsStep.next(3);
          break;
        default:
          this.bsStep.next(0);
          break;
      }
    });
    return retObs;
  }

  public authPhone(phone: string) {
    const web = this.webSvc.getCurrentWebsite();
    const request = new OrdersAuthRequestModel({phone: phone, isEmail: true});

    return this.http.post<OrdersAuthResponseModel>(`${ApiEndpoints.authEmail}::${web.id}`, request).pipe(map(f => new OrdersAuthResponseModel(f)));
  }

  loadUserData() {
    const ss = this.sessionSvc.getCurrentSS();
    if (ss.isAuth) {
      this.http.post<OtpAuthUserBaseDataModel>(ApiEndpoints.authUserData, ss).pipe(map(f => new OtpAuthUserBaseDataModel(f))).subscribe(data => {
        this.bsUserData.next(data);
        this.bsStep.next(2);
      });
    }

  }

  verifyOtp(otp: string) {
    const web = this.webSvc.getCurrentWebsite();
    const loc = this.locSvc.getCurrentLocation()
    const cart = this.cartSvc.getLatestCart(loc.id);
    const data = this.bsAuthResponses.value;
    const request = new OtpAuthRequestModel(data.token, data.isEmail, otp);

    const retObj = this.http.post<OrdersAuthResponseModel>(`${ApiEndpoints.authOtp}::${web.id}::${loc.id}::${cart?.id}`, request)
      .pipe(
        map(f => new OrdersAuthResponseModel(f))
      );

    retObj.subscribe(data => {
      if (data != null && data.responseType === EnumAuthResponseType.OtpValid) {
        this.bsStep.next(2);
        this.sessionSvc.saveToken(data.token, data.isEmail, data.id);
        this.loadUserData();
        this.cartSvc.getCart(loc.id);
      }
      // console.log(data);
    });

    return retObj;
  }

  getUserdata(): OtpAuthUserBaseDataModel {
    return this.bsUserData.value;
  }

  register(request: OtpAuthUserBaseDataModel): Observable<OrdersAuthResponseModel> {
    const web = this.webSvc.getCurrentWebsite();
    const retObj = this.http.post<OrdersAuthResponseModel>(`${ApiEndpoints.authRegister}::${web.id}`, request)
      .pipe(
        map(f => new OrdersAuthResponseModel(f))
      );

    retObj.subscribe({
      next: (data) => {
        this.bsAuthResponses.next(data);
        // console.log('Data from Register:', data);
        switch (data.responseType) {
          case EnumAuthResponseType.Ok:
            this.bsStep.next(1);
            break;
          case EnumAuthResponseType.UserAlreadyExists:
            this.bsStep.next(3);
            break;
          case EnumAuthResponseType.UserNotFound:
            this.bsStep.next(3);
            break;
          default:
            this.bsStep.next(0);
            break;
        }
      }, error: (err) => {
        // console.log('Error from Register:', err);
        if (err.status == 400) {
          const data = new OrdersAuthResponseModel(err.error);
          this.bsAuthResponses.next(data);
        }
      }
    },);

    return retObj;
  }

  resetUser() {
    this.sessionSvc.clearAuth();
    this.bsStep.next(0);
    this.bsAuthResponses.next(new OrdersAuthResponseModel());
    this.bsUserData.next(new OtpAuthUserBaseDataModel());
  }

  resendOtp() {
    const web = this.webSvc.getCurrentWebsite();
    const data = this.bsAuthResponses.value;
    const request = new OtpAuthRequestModel(data.token, data.isEmail);

    const retObs = this.http.post<OrdersAuthResponseModel>(`${ApiEndpoints.resendOtp}::${web.id}`, request)
      .pipe(
        map(f => new OrdersAuthResponseModel(f))
      );

    retObs.subscribe(data => {
      // console.log(data);
      switch (data.responseType) {
        case EnumAuthResponseType.Ok:
          this.bsAuthResponses.next(data);
          this.bsStep.next(1);
          break;
        case EnumAuthResponseType.UserNotFound:
          this.bsStep.next(3);
          break;
        default:
          this.bsStep.next(0);
          break;
      }
    });
    return retObs;
  }

  private init() {
    this.loadUserData();
  }
}
