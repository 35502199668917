import {OrderCartModel} from "./orders/OrderCartModel";
import {OrderCurbsideInfoModel} from "./OrderCurbsideInfoModel";
import {UserModel} from "./UserModel";
import {OrderTipModel} from "./OrderTipModel";
import {OrderPaymentInfoModel} from "./OrderPaymentInfoModel";
import {AuthStorageModel} from "./AuthStorageModel";
import {LocationModel} from "./LocationModel";
import {EnumOrderType} from "./enums/EnumOrderType";

export class OrderCartModelExtended extends OrderCartModel {
  public curbsideInfo: OrderCurbsideInfoModel = new OrderCurbsideInfoModel();
  public user: UserModel = new UserModel();
  public tip: OrderTipModel = new OrderTipModel();
  public paymentInfo: OrderPaymentInfoModel = new OrderPaymentInfoModel();
  public authData: Nullable<AuthStorageModel> = null;
  // public cart: OrderCartModel = new OrderCartModel();

  constructor(data: any = null) {
    super(data);

    if (data == null)
      return;

    if (data.curbsideInfo != null)
      this.curbsideInfo = new OrderCurbsideInfoModel(data.curbsideInfo);
    if (data.user != null)
      this.user = new UserModel(data.user);
    // if (data.cart != null)
    //   this.cart = new OrderCartModel(data.cart);

    if (data.tip != null)
      this.tip = new OrderTipModel(data.tip);
  }

  setCart(data: OrderCartModel) {
    // this.cart = data;
    this.fillData(data);
  }

  public getOrderTotal(location: LocationModel): number {
    const tip = this.tip.tip ?? 0;
    const tipPercent = this.tip.tipPercent ?? 0;
    const taxRate = location.taxRate();
    const subTotal = this.getTotalItemsDiscountedAmount();
    const deliveryFee = this.deliveryFee;

    let sum = subTotal + subTotal * taxRate + (this.tip.isCustom ? tip : subTotal * tipPercent);
    if (this.orderType === EnumOrderType.Delivery && !this.freeDeliveryDiscount)
      sum += deliveryFee;

    return sum;
  }

  getOrderTotalWithFees(location: LocationModel): number {
    let total = this.getOrderTotal(location);
    if (total > 0)
      total += location.customFee;
    return total;
  }

  getOrderTip(): number {
    const tip = this.tip.tip ?? 0;
    const tipPercent = this.tip.tipPercent ?? 0;
    const subTotal = this.getTotalItemsDiscountedAmount();

    return (this.tip.isCustom ? tip : subTotal * tipPercent);
  }
}

export interface SnackBarData {
  message: string;
  type: 'error' | 'warning' | 'success';
  errors: string[];
}
