import {isStringNotEmptyOrWhitespace} from "../utils/Utils";
import {ProductMenuOrderModel} from "./ProductMenuOrderModel";

export class ProductMenuContainerItemModel {
  public menus: ProductMenuOrderModel[] = [];
  public id: string = '';
  public createdOn: Date = new Date();
  public found = false;

  constructor(data: any = null) {
    if (!data)
      return;

    if (isStringNotEmptyOrWhitespace(data.id))
      this.id = data.id;
    if (data.createdOn)
      this.createdOn = new Date(data.createdOn);
    if (data.menus != null && Array.isArray(data.menus) && data.menus.length > 0)
      for (let item of data.menus) this.menus.push(new ProductMenuOrderModel(item));
    if (data.found != null)
      this.found = data.found;
  }
}
