import {Component, Input, OnInit} from '@angular/core';
import {faPencil} from '@fortawesome/pro-regular-svg-icons';
import {EnumOrderType} from "../../models/enums/EnumOrderType";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {LocationModel} from "../../models/LocationModel";
import {LocationService} from "../../services/location.service";
import {CartService} from "../../services/cart.service";
import {OrderCartModel} from "../../models/orders/OrderCartModel";

@Component({
  selector: 'app-order-type-info',
  templateUrl: './order-type-info.component.html',
  styleUrls: ['./order-type-info.component.scss']
})
export class OrderTypeInfoComponent implements OnInit {

  @Input() alignmentCss: string = 'justify-content-center';
  public faPencil = faPencil;
  public EnumOrderType = EnumOrderType;

  public localCart$: Observable<OrderCartModel>;
  public location$: Observable<LocationModel>;

  constructor(private cartSvc: CartService, private modal: MatDialog, private locSvc: LocationService) {
    this.localCart$ = this.cartSvc.cart$;
    this.location$ = this.locSvc.location$;
  }

  ngOnInit() {

  }

  openPicker(cart: OrderCartModel) {
    const cartOrder = new OrderCartModel(cart);
    this.cartSvc.openOrderTypePicker(cartOrder);
  }
}
