<!--<div mat-dialog-title>-->
<div class="px-4 pt-3">
  <div class="d-flex justify-content-between align-items-center flex-grow-1">
    <h2 class="mat-headline-5 text-uppercase">{{ product.name }}</h2>
    <button mat-dialog-close="false" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="product.labels.length>0">
    @for (lbl of product.labels; track lbl) {
      <mat-icon [svgIcon]="lbl" class="mx-2"></mat-icon>
    }
  </div>
</div>

<!--</div>-->
<mat-dialog-content>
  <form #f="ngForm" name="f">
    <mat-card-subtitle><span [innerHTML]="product.description"></span></mat-card-subtitle>
    <app-product-nutritional-info [modifierItem]="product"></app-product-nutritional-info>

    @if (product.hasImage('800x800')) {
      <div class="product-image" [ngStyle]="{'background-image': 'url(\'' + product.getImage('800x800') + '\')'}"></div>
    }

    @if (product && product.modifierGroups.length > 0) {
      <div class="mt-3">
        <mat-accordion [multi]="true" displayMode="default">
          @for (modifierGroup of product.modifierGroups; track modifierGroup) {
            @if (modifierGroup.items.length > 0) {
              <mat-expansion-panel expanded="true" class="border border-1" [ngClass]="{'border-warning': !modifierGroup.isValid(), 'border-success':
            modifierGroup.isValid()}">
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ modifierGroup.label }}</mat-panel-title>
                  <ng-container *ngIf="modifierGroup.maxItems">
                    @if (modifierGroup.minItems == null || modifierGroup.minItems < 1) {
                      <mat-panel-description>Choose up to {{ modifierGroup.maxItems }}</mat-panel-description>
                    } @else if (modifierGroup.minItems > 0 && modifierGroup.minItems < modifierGroup.maxItems) {
                      <mat-panel-description>Choose between
                        {{ modifierGroup.minItems }} and {{ modifierGroup.maxItems }} items
                      </mat-panel-description>
                    } @else if (modifierGroup.minItems > 0 && modifierGroup.minItems >= modifierGroup.maxItems) {
                      <mat-panel-description>
                        Choose {{ modifierGroup.maxItems }} items
                      </mat-panel-description>
                    }
                  </ng-container>
                </mat-expansion-panel-header>

                @if (isStringNotEmptyOrWhitespace(modifierGroup.description)) {
                  <div [innerHTML]="modifierGroup.description" class="bs-callout bs-callout-info mt-0"></div>
                }

                <ng-container *ngIf="modifierGroup.isCheckbox then multipleSelection; else singleSelection;">
                </ng-container>

                <ng-template #multipleSelection>

                  <div class="d-flex flex-grow-1 flex-column">
                    <div *ngFor="let modifierItem of modifierGroup.items; last as isLast;">
                      <div class="d-flex flex-grow-1 justify-content-start align-items-center">
                        <mat-checkbox (ngModelChange)="selectItem(modifierItem, $event)" [(ngModel)]="modifierItem.selected"
                                      [disabled]="!modifierGroup.canIncreaseQuantity() && !modifierItem.selected" [name]="modifierItem.token"
                                      class="flex-grow-1">
                          {{ modifierItem.name }}
                          @for (lbl of modifierItem.labels; track lbl) {
                            <mat-icon [svgIcon]="lbl" class="mx-1"></mat-icon>
                          }
                        </mat-checkbox>
                        <div class="d-flex justify-content-end align-items-center mat-body-medium">
                          <span [ngClass]="{'text-bold': modifierItem.selected}">{{ modifierItem.price.getPrice('+$') }}</span>
                          <app-modifier-item-quantity *ngIf="modifierItem.selected && (modifierGroup.maxItems == null || modifierGroup.maxItems > 1)"
                                                      [group]="modifierGroup"
                                                      [modifierItem]="modifierItem"></app-modifier-item-quantity>
                        </div>
                      </div>
                      @if (modifierGroup.allowModifierDescription && isStringNotEmptyOrWhitespace(modifierItem.description)) {
                        <mat-card appearance="outlined">
                          <mat-card-content class="px-3 py-2 mat-body" [innerHTML]="modifierItem.description"></mat-card-content>
                        </mat-card>
                      }
                      <app-product-nutritional-info [modifierItem]="modifierItem"></app-product-nutritional-info>
                      <mat-divider *ngIf="!isLast" class="my-2"></mat-divider>
                    </div>
                  </div>
                </ng-template>
                <ng-template #singleSelection>
                  <mat-radio-group (ngModelChange)="selectSingleItem(modifierGroup, $event)" [(ngModel)]="modifierGroup.selectedItem" [name]="modifierGroup.token">
                    <div class="d-flex flex-grow-1 flex-column gap-2">
                      <div *ngFor="let modifierItem of modifierGroup.items">
                        <div class="d-flex flex-grow-1 justify-content-start align-items-center">
                          <mat-radio-button [name]="modifierItem.token"
                                            [value]="modifierItem.token" class="flex-grow-1">
                            {{ modifierItem.name }}
                          </mat-radio-button>
                          <div class="d-flex justify-content-end align-items-center mat-body-medium">
                            <span [ngClass]="{'text-bold': modifierItem.selected}">{{ modifierItem.price.getPrice('+$') }}</span>
                          </div>
                        </div>
                        @if (modifierGroup.allowModifierDescription && isStringNotEmptyOrWhitespace(modifierItem.description)) {
                          <mat-card appearance="outlined">
                            <mat-card-content class="px-3 py-2 mat-body" [innerHTML]="modifierItem.description"></mat-card-content>
                          </mat-card>
                        }
                      </div>
                    </div>
                  </mat-radio-group>
                </ng-template>
              </mat-expansion-panel>
            }
          }
        </mat-accordion>
      </div>
    }

    <div class="bs-callout bs-callout-accent">
      <div class="bs-callout-title">Special instructions</div>
      <div class="d-flex justify-content-between align-items-center w-100">
        <span>200 characters or fewer</span>
        <span>
          <ng-container *ngIf="txtSI.value != null && txtSI.value.length > 0">{{ txtSI.value.length }} / 200</ng-container>
        </span>
      </div>
    </div>
    <mat-form-field appearance="outline" class="w-100">
        <textarea matInput #txtSI='ngModel' name="txtSI"
                  placeholder="We'll do our best to accommodate special requests but may not be able to honor all substitutes. Extra charges may apply."
                  maxlength="200" rows="4" [(ngModel)]="data.specialInstructions"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="d-flex justify-content-between align-items-stretch gap-2 flex-grow-1">
    <div class="d-flex justify-content-between align-items-center">
      <button (click)="decQuantity()" [disabled]="data.quantity <=1" mat-icon-button>
        <mat-icon>remove_circle</mat-icon>
      </button>
      <span class="quantity-text">{{ data.quantity }}</span>
      <button (click)="incQuantity()" [disabled]="data.quantity>=99" mat-icon-button matSuffix>
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
    <button #btnSave (click)="addToCart()" [disabled]="f.form.invalid || !product.isValid()" class="button-cart2" mat-flat-button>
      <div class="d-flex justify-content-between align-items-center flex-grow-1">
        <span><fa-icon [icon]="faCart"></fa-icon> Add to order</span>
        <span>{{ getOrderTotal()|currency }}</span>
      </div>

    </button>
  </div>
</mat-dialog-actions>

