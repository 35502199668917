<div class="px-4 pt-3">
  <div class="d-flex justify-content-between align-items-center flex-grow-1">
    <h2 class="mat-headline-5">Edit Address</h2>
    <button mat-dialog-close="false" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-body">
  <div [formGroup]="form">
    <div class="mb-3">
      {{data.street1}}
    </div>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Address 2</mat-label>
      <input matInput formControlName="street2" type="text" placeholder="Apartment # / Suite">
    </mat-form-field>
    <div>
      @if (isStringNotEmptyOrWhitespace(data.city)){
        {{data.city}},
      }
      {{data.state}} {{data.zip}}
    </div>

  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="dialogRef.close()">Cancel</button>
  <button (click)="save()" [disabled]="!form.valid" color="primary"
          mat-raised-button>
    Save
  </button>
</mat-dialog-actions>
