import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from "../../services/order.service";
import {Observable, Subscription} from "rxjs";
import {EnumOrderType} from "../../models/enums/EnumOrderType";
import {ActivatedRoute} from "@angular/router";
import {OrderTrackResponseModel} from "../../models/OrderTrackResponseModel";
import {WebsiteService} from "../../services/website.service";
import {LocationModel} from "../../models/LocationModel";
import {WebsiteModel} from "../../models/WebsiteModel";
import {faBadgeDollar, faKeyboard, faLocationDot, faPhone, faThermometer} from '@fortawesome/pro-light-svg-icons';
import {isStringNotEmptyGuid, Utils} from "../../utils/Utils";
import {OrderCartItemModel} from "../../models/orders/OrderCartItemModel";

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit, OnDestroy {

  public faLocation = faLocationDot;
  public faPhone = faPhone;
  public faThermometer = faThermometer;
  public faKeyboard = faKeyboard;
  public EnumOrderType = EnumOrderType;
  public order$: Observable<OrderTrackResponseModel>;
  public location?: LocationModel;
  public readonly faBadgeDollar = faBadgeDollar;
  private website!: WebsiteModel;
  private sub = new Subscription();

  constructor(private orderSvc: OrderService, private route: ActivatedRoute, private webSvc: WebsiteService) {
    this.order$ = this.orderSvc.order$;
  }

  ngOnInit() {
    this.sub.add(
      this.webSvc.website$.subscribe(data => {
        if (isStringNotEmptyGuid(data.id)) {
          this.website = data;
          this.sub.add(
            this.route.params.subscribe(routeData => {
              const token = routeData['token'];
              if (isStringNotEmptyGuid(token)) {
                this.orderSvc.findById(token);
              }
            })
          );
        }
      })
    );

    this.sub.add(this.order$.subscribe(data => {
      if (data != null && isStringNotEmptyGuid(data.locationToken)) {
        const loc = this.website.findLocation(data.locationToken);
        if (loc != null)
          this.location = loc;
      }
    }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getProgressValue(order: Nullable<OrderTrackResponseModel>) {
    let progress = 0;
    let max = 6;
    if (order == null)
      return progress;
    if (order.orderType != EnumOrderType.Delivery)
      max = 5;
    progress = order.status.id / max * 100;
    return progress;
  }

  orderItemTrackByFn(index: number, item: OrderCartItemModel) {
    return item.id;
  }

  getTaxesAndFeesTooltip(order: Nullable<OrderTrackResponseModel>) {
    if (order == null || this.location == null)
      return '';
    return Utils.getTaxesAndFeedTooltip(order, this.location);
  }


}
