import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ProductService} from "../../services/product.service";
import {ViewportScroller} from "@angular/common";
import {LocationService} from "../../services/location.service";
import {LocationModel} from "../../models/LocationModel";
import {ActivatedRoute} from "@angular/router";
import {isStringNotEmpty, isStringNotEmptyGuid} from "../../utils/Utils";
import {ProductMenuOrderModel} from "../../models/ProductMenuOrderModel";
import {CartService} from "../../services/cart.service";
import {WebsiteService} from "../../services/website.service";
import {WebsiteModel} from "../../models/WebsiteModel";
import {SidebarService} from "../../services/sidebar.service";
import {LayoutService} from "../../services/layout.service";
import {getUnixTime} from "date-fns";
import {ProductMenuContainerItemModel} from "../../models/ProductMenuContainerItemModel";

@Component({
  selector: 'app-main-shop', templateUrl: './main-shop.component.html', styleUrls: ['./main-shop.component.scss']
})
export class MainShopComponent implements OnInit, OnDestroy, AfterViewInit {

  public menus$: Observable<ProductMenuContainerItemModel>;
  public location$: Observable<LocationModel>;
  public website$: Observable<WebsiteModel>;
  private sub = new Subscription();

  constructor(public productSvc: ProductService, private scroller: ViewportScroller, private locSvc: LocationService, private route: ActivatedRoute,
              private cartSvc: CartService, private webSvc: WebsiteService, private sidebarSvc: SidebarService, private layoutSvc: LayoutService) {
    this.website$ = this.webSvc.website$;
    this.location$ = this.locSvc.location$;
    this.menus$ = this.productSvc.menus$;
  }

  ngOnInit(): void {
    this.sub.add(
      this.website$.subscribe(data => {
        if (isStringNotEmptyGuid(data.id)) {
          this.sub.add(
            this.route.params.subscribe(routeData => {
              const token = routeData['token'];
              if (isStringNotEmpty(token)) {
                // const dtNow = getUnixTime(new Date());
                // this.productSvc.executeByToken(token, dtNow);
                this.cartSvc.getCart(token);
                const loc = data.findLocation(token);
                if (loc != null)
                  this.locSvc.selectLocation(loc);
              }
            })
          );
        }
      })
    );
    this.sub.add(
      this.layoutSvc.isSmall$.subscribe(data=> {
        if (!data)
          this.sidebarSvc.open();
      })
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.webSvc.setShop(true);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  public scrollToAnchorMenu(item: ProductMenuOrderModel) {
    const element = document.getElementById(this.getMenuId(item)) as HTMLElement;
    if (element != null) {
      let left = element.offsetLeft;
      let top = element.offsetTop;
      this.scroller.scrollToPosition([left, top - 192]);
    }
  }

  public getMenuId(item: ProductMenuOrderModel): string {
    if (item == null) return '';
    return `${item.token}-${encodeURIComponent(item.name)}`;
  }


}
