import {EnumOrderType} from "./enums/EnumOrderType";
import {UserModel} from "./UserModel";
import {EnumOrderStatus} from "./enums/EnumOrderStatus";
import {OrderPaymentInfoResponseModel} from "./OrderPaymentInfoResponseModel";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class OrderResponseModel {
  public id: Nullable<number> = null;
  public token: string = '';
  public orderType: EnumOrderType = EnumOrderType.PickUp;
  public orderDate: Nullable<Date> = null;
  public isAsap: boolean = false;
  public user: UserModel = new UserModel();
  public paymentResponse: OrderPaymentInfoResponseModel = new OrderPaymentInfoResponseModel();
  public status: EnumOrderStatus = EnumOrderStatus.Error;
  public errorMessage: string = '';
  public isTest: boolean = false;
  public publishableKey: string ='';
  public stripeAccountId: string = '';
  public clientSecret: string = '';

  constructor(data: any = null) {
    if (data == null)
      return;

    if (data.id != null && !isNaN(parseInt(data.id, 10)))
      this.id = parseInt(data.id, 10);
    if (isStringNotEmptyGuid(data.token))
      this.token = data.token;
    if (isStringNotEmptyOrWhitespace(data.errorMessage))
      this.errorMessage = data.errorMessage;
    if (data.isAsap)
      this.isAsap = true;
    if (data.orderType != null && !isNaN(parseInt(data.orderType, 10)))
      this.orderType = parseInt(data.orderType, 10);
    if (data.orderDate != null)
      this.orderDate = new Date(data.orderDate);
    if (data.user != null)
      this.user = new UserModel(data.user);
    if (data.paymentResponse != null)
      this.paymentResponse = new OrderPaymentInfoResponseModel(data.paymentResponse);
    if (data.status != null && !isNaN(parseInt(data.status, 10)))
      this.status = parseInt(data.status, 10);
    if (data.isTest)
      this.isTest = true;
    if (isStringNotEmptyOrWhitespace(data.publishableKey))
      this.publishableKey = data.publishableKey;
    if (isStringNotEmptyOrWhitespace(data.stripeAccountId))
      this.stripeAccountId = data.stripeAccountId;
    if (isStringNotEmptyOrWhitespace(data.clientSecret))
      this.clientSecret = data.clientSecret;
  }
}

