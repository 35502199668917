<div class="py-5">
  <h1 class="mat-headline-4 text-center">Locations</h1>

  <div class="row row-cols-1 row-cols-lg-2 g-3">
    <div *ngFor="let item of (website$|async)?.locations" class="col">
      <div (click)="selectLocation(item)" class="order-card">
        <div class="order-card-left flex-column">
          <div>
            <p class="order-card-title">{{item.friendlyName}}</p>
            <p class="order-card-subtitle">{{item.street1}} {{item.street2}}, {{item.city}}
              , {{item.state}} {{item.zip}}</p>
          </div>
          <div class="order-card-left-bottom">
            <app-location-availability [location]="item" [showMoreInfo]="false"
                                       style="width: 100%"></app-location-availability>

          </div>

        </div>
        <div class="order-card-right">
          <div class="p-4">
            <fa-icon [icon]="fadBuilding" size="6x"></fa-icon>
          </div>

        </div>
        <div class="order-card-overlay d-flex align-items-center justify-content-center">
        </div>
      </div>
    </div>
  </div>
</div>
