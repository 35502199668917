import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LocationModel} from "../../models/LocationModel";
import {Observable} from "rxjs";
import {WebsiteService} from "../../services/website.service";
import {WebsiteModel} from "../../models/WebsiteModel";
import {faFacebookSquare, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {EnumWebsiteModules} from "../../models/enums/EnumWebsiteModules";

@Component({
  selector: 'app-location-info-dialog',
  templateUrl: './location-info-dialog.component.html',
  styleUrls: ['./location-info-dialog.component.scss']
})
export class LocationInfoDialogComponent implements OnInit {
  public faFacebookSquare = faFacebookSquare;
  public faInstagram = faInstagram;
  public faTwitter = faTwitter;
  public website!: WebsiteModel;
  private website$: Observable<WebsiteModel>;

  constructor(public dialogRef: MatDialogRef<LocationInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: LocationModel,
              private webSvc: WebsiteService) {
    this.website$ = this.webSvc.website$;
  }

  ngOnInit(): void {
    this.website$.subscribe(data => {
      this.website = data;
    })
  }

  protected readonly EnumWebsiteModules = EnumWebsiteModules;
}
