import {environment} from "../../environments/environment";

export const ApiEndpoints = {
  website: environment.APIBaseURL + 'api/website',
  categoriesGet: environment.APIBaseURL + 'api/category',

  menuItemsGet: environment.APIBaseURL + 'api/menu/items',
  productsGetList: environment.APIBaseURL + 'api/website/product-list',
  websiteImagesGet: environment.APIBaseURL + "api/website/images",

  cartInitialize: environment.APIBaseURL + 'api/cart/init',
  cartValidate: environment.APIBaseURL + 'api/cart/validate',
  cartSave: environment.APIBaseURL + 'api/cart/save',
  cartApplyDiscount: environment.APIBaseURL + 'api/cart/apply-discount-code',
  cartRemoveDiscount: environment.APIBaseURL + 'api/cart/remove-discount-code',

  orderCreate: environment.APIBaseURL + 'api/order/create-order',
  orderCreatePop: environment.APIBaseURL + 'api/order/create-order-pop',
  orderCreateFree: environment.APIBaseURL + 'api/order/create-order-free',
  orderTrack: environment.APIBaseURL + 'api/order/track',
  authEmail: environment.APIBaseURL + 'api/user/email-auth',
  authPhone: environment.APIBaseURL + 'api/user/phone-auth',
  authOtp: environment.APIBaseURL + 'api/user/verify-otp',
  resendOtp: environment.APIBaseURL + 'api/user/resend-otp',
  authUserData: environment.APIBaseURL + 'api/user/load-user-auth',
  authRegister: environment.APIBaseURL + 'api/user/register',

  paymentIntent: environment.APIBaseURL + 'api/payment/payment-intent',

  addressSearch: environment.APIBaseURL + 'api/address/search',
  zipCodesSearch: environment.APIBaseURL + 'api/address/zip-codes',
  zipCodesSelect: environment.APIBaseURL + 'api/address/zip-codes-select',
  citiesSearch: environment.APIBaseURL + 'api/address/cities',
  statesSearch: environment.APIBaseURL + 'api/address/states',
  addressSelect: environment.APIBaseURL + 'api/address/select'

}
