import {guidEmpty, isStringNotEmptyGuid} from "../utils/Utils";

export class BaseModel {
  public id: string = guidEmpty;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyGuid(data.id))
      this.id = data.id;
  }
}

