import {SessionStorageService} from "../services/session-storage.service";
import {AbstractControl, AsyncValidatorFn} from "@angular/forms";
import {of} from "rxjs";

export function isAuthenticatedValidator(sessionSvc: SessionStorageService): AsyncValidatorFn {
  return (control: AbstractControl<any, any>) => {

    if (control.value)
      return of(null);

    return sessionSvc.isAuth() ? of(null) : of({auth: true});
  }
}
