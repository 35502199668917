import {EnumPaymentProvider} from "./enums/EnumPaymentProvider";
import {isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class OrderPaymentInfoModel {
  public paymentProvider: EnumPaymentProvider = EnumPaymentProvider.CreditCard;
  public otc: string = '';
  public otcDescriptor: string = '';

  constructor(data: any = null) {
    if (data == null)
      return;

    if (data.paymentProvider != null && !isNaN(parseInt(data.paymentProvider, 10)))
      this.paymentProvider = parseInt(data.paymentProvider, 10);
    if (isStringNotEmptyOrWhitespace(data.otc))
      this.otc = data.otc;
    if (isStringNotEmptyOrWhitespace(data.otcDescriptor))
      this.otcDescriptor = data.otcDescriptor;
  }
}

