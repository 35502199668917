import {Component, OnInit} from '@angular/core';
import {faShoppingCart} from "@fortawesome/pro-light-svg-icons";
import {CartService} from "../../services/cart.service";
import {Observable} from "rxjs";
import {OrderCartModel} from "../../models/orders/OrderCartModel";
import {OrderCartItemModel} from "../../models/orders/OrderCartItemModel";
import {SidebarService} from "../../services/sidebar.service";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  public falCart = faShoppingCart;
  public cart$: Observable<OrderCartModel>;

  constructor(private cartSvc: CartService, public sidebarSvc: SidebarService) {
    this.cart$ = this.cartSvc.cart$;
  }

  ngOnInit(): void {
  }

  edit(item: OrderCartItemModel) {
    this.cartSvc.openDialog(new OrderCartItemModel(item), true);
  }

  remove(item: OrderCartItemModel) {
    this.cartSvc.removeFromCart(item);
  }

  getOrderTotal(cart: OrderCartModel) {
    let sum = 0;

    if (cart != null && cart.items != null && cart.items.length > 0) {
      for (let item of cart.items)
        sum += item.getTotal();
    }

    return sum;
  }

  getQuantityTotal(cart: OrderCartModel) {
    let sum = 0;

    if (cart != null) {
      return cart.getTotalItemsCount();
    }

    return sum;
  }
}
