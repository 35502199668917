import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {SnackBarData} from "../../models/OrderCartModelExtended";

@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: 'custom-snack-bar.component.html',
  styleUrl: 'custom-snack-bar.component.scss'
})
export class CustomSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData, public snackRef: MatSnackBarRef<CustomSnackBarComponent>) {
  }

  close() {
    this.snackRef.dismiss();
  }
}
