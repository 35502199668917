import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, Observable, share} from "rxjs";
import {PendingService} from "./pending.service";
import {Utils} from "../../utils/Utils";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private pendingService: PendingService, private http: HttpClient) {
  }

  public get<T>(url: string, options?: any): Observable<T> {
    return this.pendingService.intercept(url, this.http.get<T>(url, options).pipe( share()));
  }

  public post<T>(url: string, body: any, options?: any): Observable<T> {
    return this.pendingService.intercept(url, this.http.post<T>(url, body, options)).pipe( share());
  }

  public put<T>(url: string, body: any, options?: any): Observable<T> {
    return this.pendingService.intercept(url, this.http.put<T>(url, body, options)).pipe( share());
  }

  public delete<T>(url: string, options?: any): Observable<T> {
    return this.pendingService.intercept(url, this.http.delete<T>(url, options)).pipe( share());
  }
}
