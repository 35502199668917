import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatChipsModule} from "@angular/material/chips";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {MatRippleModule} from "@angular/material/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgScrollbarModule} from "ngx-scrollbar";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SpyOnDirective} from "./directives/spy-on.directive";
import {SpyTargetDirective} from "./directives/spy-target.directive";
import {AuthHttpInterceptor} from "./interceptors/auth-http-interceptor";
import {MainLayoutComponent} from './pages/main-layout/main-layout.component';
import {FooterComponent} from './components/footer/footer.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {MenuItemDialogComponent} from './dialogs/menu-item-dialog/menu-item-dialog.component';
import {DialogHeaderControlsComponent} from './dialogs/dialog-header-controls/dialog-header-controls.component';
import {LocationSelectorComponent} from './pages/location-selector/location-selector.component';
import {CheckoutComponent} from './pages/checkout/checkout.component';
import {CartComponent} from './components/cart/cart.component';
import {LocationInfoComponent} from './components/location-info/location-info.component';
import {LocationInfoDialogComponent} from './dialogs/location-info-dialog/location-info-dialog.component';
import {LocationAvailabilityComponent} from './components/location-availability/location-availability.component';
import {ModifierItemQuantityComponent} from './components/modifier-item-quantity/modifier-item-quantity.component';
import {OrderTypeInfoComponent} from './components/order-type-info/order-type-info.component';
import {OrderTypeChooserDialogComponent} from './dialogs/order-type-chooser-dialog/order-type-chooser-dialog.component';
import {MainShopComponent} from "./components/main-shop/main-shop.component";

import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask} from "ngx-mask";
import {MatBadgeModule} from "@angular/material/badge";
import {TrackingComponent} from './pages/tracking/tracking.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatStepperModule} from "@angular/material/stepper";
import {MatDividerModule} from "@angular/material/divider";
import {AuthComponent} from './components/auth/auth.component';
import {NgOtpInputModule} from "ng-otp-input";
import {provideNgxStripe, StripeElementsDirective, StripePaymentElementComponent} from "ngx-stripe";
import {ProductNutritionalInfoComponent} from "./components/product-nutritional-info/product-nutritional-info.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {AuthCustomValidatorDirective} from "./directives/auth-custom-validator.directive";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {CustomSnackBarComponent} from "./components/custom-snack-bar/custom-snack-bar.component";
import {AddressDialogComponent} from './dialogs/address-dialog/address-dialog.component';
import {NgProgressModule} from "ngx-progressbar";
import {NgProgressHttpModule} from "ngx-progressbar/http";
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import {environment} from "../environments/environment";


@NgModule({
  declarations: [
    AppComponent,
    SpyOnDirective,
    SpyTargetDirective,
    MainLayoutComponent,
    FooterComponent,
    MainShopComponent,
    MenuItemComponent,
    MenuItemDialogComponent,
    DialogHeaderControlsComponent,
    LocationSelectorComponent,
    CheckoutComponent,
    CartComponent,
    LocationInfoComponent,
    LocationInfoDialogComponent,
    LocationAvailabilityComponent,
    ModifierItemQuantityComponent,
    OrderTypeInfoComponent,
    OrderTypeChooserDialogComponent,
    TrackingComponent,
    AuthComponent,
    AuthCustomValidatorDirective,
    CustomSnackBarComponent,
    AddressDialogComponent

  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgProgressModule,
    NgProgressHttpModule,

    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatStepperModule,
    MatDividerModule,

    NgScrollbarModule,

    FontAwesomeModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    NgOtpInputModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    ProductNutritionalInfoComponent,
    MatAutocompleteModule,
    MatProgressSpinner,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    provideEnvironmentNgxMask({clearIfNotMatch: true}),
    provideNgxStripe(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
