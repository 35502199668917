<mat-toolbar>
  <mat-toolbar-row class="d-flex align-items-center justify-content-center flex-wrap">
    <ng-container *ngFor="let item of (website$|async)?.menuItems">
      <ng-container *ngIf="item.isLocationSpecific then multiMenu; else singleMenu;">
      </ng-container>

      <ng-template #singleMenu>
        <a *ngIf="!item.isHidden" [href]="getMenuLink(item.link)" class="mx-2" mat-button>{{item.label}}</a>
      </ng-template>

      <ng-template #multiMenu>
        <button [matMenuTriggerFor]="menu" class="mx-2" mat-button>{{item.label}}</button>
        <mat-menu #menu="matMenu" yPosition="above">
          <a *ngFor="let loc of website.locations" [href]="getMenuLink(item.link, loc.identifier)" mat-menu-item>{{loc.name}}</a>
        </mat-menu>
      </ng-template>
    </ng-container>
  </mat-toolbar-row>
  <mat-toolbar-row class="d-flex align-items-center justify-content-center">
    <ng-container *ngIf="website">
      <a *ngIf="isStringNotEmptyGuid(website.id)" class="logo-link" href="/">
        <img [src]="website.getSetting('GENERAL.LOGO.URL')" alt="Logo" class="logo-image"/>
      </a>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
<div class="footer-powered w-100 text-center py-2">
  powered by <a
  href="https://orders.swiftserve.io/local"><img alt="Discover SwiftServe Powered Restaurants" class="ml-4"
                                                 src="https://assets.swiftserve.io/logo4.png"></a>
</div>
