import {addHours, endOfDay, endOfToday, format, getDay, isAfter, isBefore, startOfDay, startOfToday} from "date-fns";
import {EnumDays} from "./enums/EnumDays";
import {ProductOrderModel} from "./ProductOrderModel";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class ProductMenuOrderModel {
  public token: string = '';
  public description = '';
  public name = '';
  public sortOrder = 0;
  public startHour: Nullable<number> = null;
  public endHour: Nullable<number> = null;
  public hideMenuImages = false;
  public hasProducts = false;
  public isAvailableAllTimes = false;
  public daysSelected = 0;

  public subMenus: ProductMenuOrderModel[] = [];
  public items: ProductOrderModel[] = [];
  public itemsCount = 0;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyGuid(data.token))
      this.token = data.token;
    if (isStringNotEmptyOrWhitespace(data.name))
      this.name = data.name;
    if (isStringNotEmptyOrWhitespace(data.description))
      this.description = data.description;

    if (data.sortOrder != null && !isNaN(parseInt(data.sortOrder, 10)))
      this.sortOrder = parseInt(data.sortOrder, 10);
    if (data.startHour != null && !isNaN(parseInt(data.startHour, 10)))
      this.startHour = parseInt(data.startHour, 10);
    if (data.endHour != null && !isNaN(parseInt(data.endHour, 10)))
      this.endHour = parseInt(data.endHour, 10);
    if (data.daysSelected != null && !isNaN(parseInt(data.daysSelected, 10)))
      this.daysSelected = parseInt(data.daysSelected, 10);

    if (data.hideMenuImages) {
      this.hideMenuImages = true;
    }
    if (data.hasProducts) {
      this.hasProducts = true;
    }
    if (data.isAvailableAllTimes) {
      this.isAvailableAllTimes = true;
    }

    if (data.subMenus != null && Array.isArray(data.subMenus) && data.subMenus.length > 0)
      for (let item of data.subMenus) this.subMenus.push(new ProductMenuOrderModel(item));
    if (data.items != null && Array.isArray(data.items) && data.items.length > 0)
      for (let item of data.items) this.items.push(new ProductOrderModel(item));

    this.calculateItemsCount();
  }

  public getTimeAvailabilityString(): string {
    if (this.isAvailableAllTimes)
      return 'all day';

    let start = startOfToday();
    let end = startOfToday();

    if (this.startHour != null)
      start = addHours(start, this.startHour);
    if (this.endHour != null)
      end = addHours(end, this.endHour);
    else end = endOfToday();

    return `${format(start, 'h a')} - ${format(end, 'h a')}`;
  }

  public canDisplay(): boolean {
    if (this.itemsCount == 0)
      return false;

    const dtNow = new Date();
    const dtStart = this.startHour != null && this.startHour > 0 && this.startHour < 24 ? addHours(startOfDay(dtNow), this.startHour) : startOfDay(dtNow);
    const dtEnd = this.endHour != null && this.endHour > 0 && this.endHour < 24 ? addHours(startOfDay(dtNow), this.endHour) : endOfDay(dtNow);
    const dayOfWeek = getDay(dtNow); // 0 = Sunday, 1 = Monday etc.
    let dayOfWeekEnum: EnumDays;

    switch (dayOfWeek) {
      case 0: {
        dayOfWeekEnum = EnumDays.Sunday;
        break;
      }
      case 1: {
        dayOfWeekEnum = EnumDays.Monday;
        break;
      }
      case 2: {
        dayOfWeekEnum = EnumDays.Tuesday;
        break;
      }
      case 3: {
        dayOfWeekEnum = EnumDays.Wednesday;
        break;
      }
      case 4: {
        dayOfWeekEnum = EnumDays.Thursday;
        break;
      }
      case 5: {
        dayOfWeekEnum = EnumDays.Friday;
        break;
      }
      default: {
        dayOfWeekEnum = EnumDays.Saturday;
        break;
      }
    }

    if (this.isAvailableAllTimes) {
      return (this.daysSelected & dayOfWeekEnum) == dayOfWeekEnum;
    } else {
      if ((this.daysSelected & dayOfWeekEnum) != dayOfWeekEnum)
        return false;
      else {
        return isAfter(dtNow, dtStart) && isBefore(dtNow, dtEnd);
      }
    }
  }

  public findProduct(id: string): Nullable<ProductOrderModel> {
    if (this.hasProducts) {
      for (let product of this.items) {
        if (product.token === id)
          return product;
      }
    } else {
      for (let subMenu of this.subMenus) {
        let product = subMenu.findProduct(id);
        if (product != null)
          return product;
      }
    }
    return null;
  }

  private calculateItemsCount() {
    let count = 0;
    if (this.hasProducts) {
      count += this.items.length;
    }
    else {
      for (let subMenu of this.subMenus) {
        count += subMenu.itemsCount;
      }
    }
    this.itemsCount = count;
  }
}

