import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {WebsiteModel} from "../../models/WebsiteModel";
import {WebsiteService} from "../../services/website.service";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {ScrollSpyService} from "../../services/utils/scroll-spy.service";
import {DOCUMENT, ViewportScroller} from "@angular/common";

import {LayoutService} from "../../services/layout.service";
import {OrderCartModel} from "../../models/orders/OrderCartModel";
import {CartService} from "../../services/cart.service";
import {MatSidenav} from "@angular/material/sidenav";
import {SidebarService} from "../../services/sidebar.service";
import {getMenuLinkUtil, isStringNotEmptyGuid} from "../../utils/Utils";
import {environment} from "../../../environments/environment";
import {animate, keyframes, style, transition, trigger} from "@angular/animations";
import {MenuItemModel} from "../../models/MenuItemModel";

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [trigger('fadeInOut', [transition(':enter', [
      style({opacity: 0}),
      animate('350ms', style({opacity: 1}))
    ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('350ms', style({opacity: 0}))
      ])
    ]
  ),
    trigger('rotate', [
      transition(':enter', [
        animate('1000ms', keyframes([
          style({transform: 'rotate(0deg)', offset: 0}),
          style({transform: 'rotate(2turn)', offset: 1})
        ]))
      ])
    ])]
})
export class MainLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  public website$: Observable<WebsiteModel>;
  public isSmall: boolean = false;
  // public isHandset$: Observable<boolean>;
  public isSmall$: Observable<boolean>;
  public isXSmall$: Observable<boolean>;
  public isShop$: Observable<boolean>;
  public readonly cart$: Observable<OrderCartModel>;

  protected website!: WebsiteModel;
  protected readonly isStringNotEmptyGuid = isStringNotEmptyGuid;


  constructor(private websiteSvc: WebsiteService, public router: Router, public title: Title, private scrollspyService: ScrollSpyService,
              private scroller: ViewportScroller, @Inject(DOCUMENT) private document: Document, private layoutSvc: LayoutService, private cartSvc: CartService,
              public sidebarSvc: SidebarService, private sanitizer: DomSanitizer) {
    this.website$ = this.websiteSvc.website$;
    this.isShop$ = this.websiteSvc.isShop$;
    this.cart$ = this.cartSvc.cart$;
    // this.categories$ = this.catSvc.categories$;

    // this.isHandset$ = this.layoutSvc.isHandset$;
    this.isSmall$ = this.layoutSvc.isSmall$;
    this.isXSmall$ = this.layoutSvc.isXSmall$;
  }

  ngOnInit(): void {
    // this.catSvc.execute();


    this.website$.subscribe(data => {
        this.website = data;
        const websiteName = data?.getSetting("GENERAL.HOST.TITLE");
        const themeId = data?.getSetting("orders.themeid");
        if (websiteName == null || websiteName == '')
          this.title.setTitle(`Online Orders`);
        else
          this.title.setTitle(`${data.getSetting("GENERAL.HOST.TITLE")} - Online Orders`);

        if (themeId != null && themeId != '') {
          this.document.body.classList.forEach((value) => {
            if (value.startsWith('theme'))
              this.document.body.classList.remove(value);
          });
          this.document.body.classList.add(themeId);
        }
      }
    );

    this.isSmall$.subscribe(data => {
      this.isSmall = data;
      // console.log('isSmall', data);
    })
  }

  ngAfterViewInit() {
    this.scrollspyService.spy({
      thresholdTop: 192
    });
    this.sidebarSvc.register(this.sidenav);
    if (!this.isSmall && this.websiteSvc.getIsShop())
      this.sidebarSvc.open();
  }

  getMenuLink(link: string, secondLevel: Nullable<string> = null) {
    return getMenuLinkUtil(link, secondLevel, this.website, this.sanitizer);
  }

  buildOnlineOrdersLink() {
    return `${environment.APIBaseURL}`;
  }

  handleClick(item: MenuItemModel) {
    item.collapsed = !item.collapsed;

  }
}
