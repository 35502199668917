import {ProductModifierItemOrderModel} from "./ProductModifierItemOrderModel";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class ProductModifierGroupOrderModel {
  public name = '';
  public description = '';
  public token = '';
  public label = '';
  public sortOrder = 0;
  public minItems = 0;
  public maxItems: Nullable<number> = null;
  public isRequired = false;
  public isCheckbox = false;
  public items: ProductModifierItemOrderModel[] = [];
  public allowModifierDescription = false;

  public selectedItem: string = '';

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyGuid(data.token))
      this.token = data.token;
    if (isStringNotEmptyOrWhitespace(data.name))
      this.name = data.name;
    if (isStringNotEmptyOrWhitespace(data.description))
      this.description = data.description;
    if (isStringNotEmptyOrWhitespace(data.label))
      this.label = data.label;

    if (data.sortOrder != null && !isNaN(parseInt(data.sortOrder, 10)))
      this.sortOrder = parseInt(data.sortOrder, 10);
    if (data.minItems != null && !isNaN(parseInt(data.minItems, 10)))
      this.minItems = parseInt(data.minItems, 10);
    if (data.maxItems != null && !isNaN(parseInt(data.maxItems, 10)))
      this.maxItems = parseInt(data.maxItems, 10);

    if (data.isRequired)
      this.isRequired = true;
    if (data.isCheckbox)
      this.isCheckbox = true;
    if (data.allowModifierDescription)
      this.allowModifierDescription = true;

    if (data.items != null && Array.isArray(data.items) && data.items.length > 0) {
      for (let item of data.items) this.items.push(new ProductModifierItemOrderModel(item));
    }
  }

  public canIncreaseQuantity(itemQuantity: number = 1) {
    if (this.maxItems == null)
      return true;
    let total = 0;

    for (let item of this.items) {
      if (item.selected)
        total += item.quantity;
    }

    return total < this.maxItems * itemQuantity;
  }

  isValid(): boolean {
    if (!this.isRequired || this.items.length == 0)
      return true;

    if (this.isRequired) {
      let totalSelected = 0;
      for (let item of this.items) {
        if (item.selected)
          totalSelected += item.quantity;
      }
      if (totalSelected < this.minItems)
        return false;
    }
    return true;
  }

}
