import {EnumAuthResponseType} from "./EnumAuthResponseType";
import {parseISO} from "date-fns";
import {guidEmpty, isStringNotEmptyOrWhitespace} from "../../utils/Utils";

export class OrdersAuthResponseModel {
  public id: string = guidEmpty;
  public token: string = '';
  public responseType: EnumAuthResponseType = EnumAuthResponseType.None;
  public message: string = '';
  public expiresOn: Nullable<Date> = null;
  public isEmail: boolean = false;
  public entity: string = '';

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.id))
      this.id = data.id;
    if (isStringNotEmptyOrWhitespace(data.token))
      this.token = data.token;
    if (data.responseType != null && !isNaN(parseInt(data.responseType)))
      this.responseType = parseInt(data.responseType);
    if (isStringNotEmptyOrWhitespace(data.message))
      this.message = data.message;
    if (data.expiresOn != null)
      this.expiresOn = parseISO(data.expiresOn);
    if (data.isEmail != null)
      this.isEmail = data.isEmail;
    if (isStringNotEmptyOrWhitespace(data.entity))
      this.entity = data.entity;
  }
}

