import {EnumOrderType} from "./enums/EnumOrderType";
import {OrderStatusModel} from "./OrderStatusModel";
import {OrderCartItemModel} from "./orders/OrderCartItemModel";
import {LocationModel} from "./LocationModel";
import {OrderCurbsideInfoModel} from "./OrderCurbsideInfoModel";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class OrderTrackResponseModel {
  public id: Nullable<number> = null;
  public token: string = '';
  public orderType: EnumOrderType = EnumOrderType.PickUp;
  public orderConfirmedDate: Nullable<Date> = null;
  public curbsideInfo: OrderCurbsideInfoModel = new OrderCurbsideInfoModel();
  public amount: number = 0;
  public amountTax: number = 0;
  public amountTip: number = 0;
  public amountTotal: number = 0;
  public deliveryFee: number = 0;
  public status: OrderStatusModel = new OrderStatusModel();
  public errorMessage: string = '';
  public items: OrderCartItemModel[] = [];
  public location: LocationModel = new LocationModel();
  public locationToken: string = '';
  public amountFees: number=0;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (data.id != null && !isNaN(parseInt(data.id, 10)))
      this.id = parseInt(data.id, 10);
    if (isStringNotEmptyGuid(data.token))
      this.token = data.token;
    if (isStringNotEmptyGuid(data.locationToken))
      this.locationToken = data.locationToken;
    if (isStringNotEmptyOrWhitespace(data.errorMessage))
      this.errorMessage = data.errorMessage;

    if (data.orderType != null && !isNaN(parseInt(data.orderType, 10)))
      this.orderType = parseInt(data.orderType, 10);
    if (data.orderConfirmedDate != null)
      this.orderConfirmedDate = new Date(data.orderConfirmedDate);
    if (data.amount != null && !isNaN(parseFloat(data.amount)))
      this.amount = parseFloat(data.amount);
    if (data.amountTax != null && !isNaN(parseFloat(data.amountTax)))
      this.amountTax = parseFloat(data.amountTax);
    if (data.amountTip != null && !isNaN(parseFloat(data.amountTip)))
      this.amountTip = parseFloat(data.amountTip);
    if (data.amountTotal != null && !isNaN(parseFloat(data.amountTotal)))
      this.amountTotal = parseFloat(data.amountTotal);
    if (data.deliveryFee != null && !isNaN(parseFloat(data.deliveryFee)))
      this.deliveryFee = parseFloat(data.deliveryFee);
    if (data.amountFees != null && !isNaN(parseFloat(data.amountFees)))
      this.amountFees = parseFloat(data.amountFees);
    if (data.status != null)
      this.status = new OrderStatusModel(data.status);
    if (data.items != null && Array.isArray(data.items) && data.items.length > 0)
      for (let item of data.items) this.items.push(new OrderCartItemModel(item));
    if (data.location != null)
      this.location = new LocationModel(data.location);
  }

  getOrderTypeString() {
    switch (this.orderType) {
      case EnumOrderType.Delivery:
        return "Delivery";
      case EnumOrderType.PickUp:
        return "Pick Up";
      case EnumOrderType.DineIn:
        return "Dine In";
    }
  }

  getTotalItemsAmount(){
    return this.amount;
  }

  getTotalItemsDiscountedAmount(){
    return this.amount;
  }

}

