export class BaseModelInt {
  public id: number = 0;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (data.id != null && !isNaN(parseInt(data.id, 10)))
      this.id = parseInt(data.id, 10);
  }
}
