import {AfterViewInit, Component, OnDestroy, OnInit, signal, ViewChild} from '@angular/core';
import {catchError, Observable, Subscription} from "rxjs";
import {LocationModel} from "../../models/LocationModel";
import {LocationService} from "../../services/location.service";
import {OrderCartModel} from "../../models/orders/OrderCartModel";
import {CartService} from "../../services/cart.service";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {ProductService} from "../../services/product.service";
import {WebsiteService} from "../../services/website.service";
import {OrderCartModelExtended} from "../../models/OrderCartModelExtended";
import {OrderCartItemModel} from "../../models/orders/OrderCartItemModel";
import {EnumPaymentProvider} from "../../models/enums/EnumPaymentProvider";

import {SidebarService} from "../../services/sidebar.service";
import {OrderService} from "../../services/order.service";
import {OrderResponseModel} from "../../models/OrderResponseModel";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EnumOrderStatus} from "../../models/enums/EnumOrderStatus";
import {ActivatedRoute, Router} from "@angular/router";

import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace, Utils, valueNotEmptyGuidValidator} from "../../utils/Utils";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {SessionStorageService} from "../../services/session-storage.service";
import {MatStepper} from "@angular/material/stepper";
import {UserModel} from "../../models/UserModel";
import {AuthService} from "../../services/auth.service";
import {StripeElementsOptions, StripePaymentElementOptions} from "@stripe/stripe-js";
import {injectStripe, StripeElementsDirective, StripePaymentElementComponent, StripeServiceInterface} from "ngx-stripe";
import {environment} from "../../../environments/environment";
import {faInfoCircle} from "@fortawesome/pro-duotone-svg-icons";
import {getUnixTime} from "date-fns";
import {EnumOrderType} from "../../models/enums/EnumOrderType";
import {AddressService} from "../../services/address.service";
import {OrderUserAddressModel} from "../../models/orders/OrderUserAddressModel";
import {AddressDialogComponent} from "../../dialogs/address-dialog/address-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {LayoutService} from "../../services/layout.service";
import * as Sentry from "@sentry/angular";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('stepMaster', {static: false}) stepMaster!: MatStepper;
  // @ViewChild('sElements', {static: false})
  public paymentElement!: StripePaymentElementComponent;
  public isLoadingAddress: boolean = false;
  public location$: Observable<LocationModel>;
  public cart$: Observable<OrderCartModel>;
  public addressHints$ = this.addressSvc.results$;
  public cartExtended: OrderCartModelExtended = new OrderCartModelExtended();
  public EnumPaymentProvider = EnumPaymentProvider;
  public orderResponse: Nullable<OrderResponseModel> = null;
  public curbSideGroup!: FormGroup;
  public tipGroup!: FormGroup;
  public addressHintGroup!: FormGroup;
  public addressSelectionGroup!: FormGroup;
  public discountGroup!: FormGroup;
  public isAuth = false;
  public stripe: StripeServiceInterface;
  public isSmall$: Observable<boolean>;
  paying = signal(false);
  paymentStep = signal(false);
  public elementsOptions: StripeElementsOptions;
  public paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      // radios: false,
      // spacedAccordionItems: false
    }
  };
  public readonly faInfoCircle = faInfoCircle;
  protected readonly EnumOrderType = EnumOrderType;

  protected readonly isStringNotEmptyOrWhitespace = isStringNotEmptyOrWhitespace;
  protected readonly isStringNotEmptyGuid = isStringNotEmptyGuid;
  protected readonly Object = Object;
  private elements!: StripeElementsDirective;
  private sub = new Subscription();

  constructor(private locSvc: LocationService, public productSvc: ProductService, private webSvc: WebsiteService, private cartSvc: CartService, private sidebarSvc: SidebarService, private orderSvc: OrderService,
              private snack: MatSnackBar, private router: Router, public sessionSvc: SessionStorageService, private authSvc: AuthService, private route: ActivatedRoute, public addressSvc: AddressService,
              private modal: MatDialog, private layoutSvc: LayoutService) {
    this.location$ = this.locSvc.location$;
    this.cart$ = this.cartSvc.cart$;
    this.stripe = injectStripe(this.locSvc.getCurrentLocation().isStripeTest ? environment.stripePkTest : environment.stripePkLive, {stripeAccount: this.locSvc.getCurrentLocation().stripeAccountId});
    this.elementsOptions = {
      locale: 'en',
      appearance: {
        theme: this.webSvc.getCurrentWebsite().getSetting("ORDERS.DARKTHEME") == 'true' ? 'night' : 'stripe',
        disableAnimations: false,

      },

    };
    this.isSmall$ = this.layoutSvc.isXSmall$;
  }

  @ViewChild(StripeElementsDirective, {static: false}) set elementDirective(elementDirective: StripeElementsDirective) {
    if (elementDirective) {
      this.elements = elementDirective;
    }
  }

  @ViewChild(StripePaymentElementComponent, {static: false}) set paymentElementComponent(elementComponent: StripePaymentElementComponent) {
    if (elementComponent) {
      this.paymentElement = elementComponent;
    }

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.addressHintGroup = new FormGroup({
      addressHint: new FormControl("", [Validators.required, Validators.maxLength(110)])
    });

    this.addressSelectionGroup = new FormGroup({
      selectedAddressId: new FormControl(this.cartExtended.selectedAddressId, [Validators.required, valueNotEmptyGuidValidator()])
    });

    this.curbSideGroup = new FormGroup({
      isCurbsidePickup: new FormControl(false),
      vehicleMake: new FormControl({value: '', disabled: true}, [Validators.required, Validators.maxLength(120)]),
      vehicleColor: new FormControl({value: '', disabled: true}),
    });

    this.tipGroup = new FormGroup({
      tipPercent: new FormControl(0),
      tip: new FormControl({value: 0, disabled: true}, [Validators.required, Validators.max(500), Validators.min(0)])
    });

    this.discountGroup = new FormGroup({
      discountCode: new FormControl('', {validators: [Validators.required]})
    });

    this.sub.add(
      this.cart$.subscribe(data => {
        if (!isStringNotEmptyGuid(this.cartExtended.id) && isStringNotEmptyGuid(data.id)) {
          this.cartSvc.validate(data);
        } else if (isStringNotEmptyGuid(data.locationToken)) {
          const dtNow = getUnixTime(new Date());
          // this.productSvc.executeByToken(data.locationToken, dtNow);
        }
        if (data != null && data.items.length == 0 && isStringNotEmptyGuid(data.id)) {
          this.router.navigate(['..', 'l', this.locSvc.getCurrentLocation().id], {relativeTo: this.route});
        }
        if (data != null && data.items.length > 0) {
          this.cartExtended.setCart(data);
          this.initForms();
        }
      })
    );
  }

  ngAfterViewInit() {
    this.sub.add(
      this.stepMaster.selectedIndexChange.subscribe(data => {
        // console.log(`Step ${data} / ${this.stepMaster.steps.length}`);
        if (data == this.stepMaster.steps.length - 1) {
          this.getPaymentIntent();
        }
      }));
    this.sub.add(
      this.location$.subscribe(location => {
        if (isStringNotEmptyGuid(location.id)) {
          // this.location = location;
          this.cartSvc.getCart(location.id);
        }
      })
    );

    this.stepMaster.selectedIndexChange.subscribe(data => {
      if (data == this.stepMaster.steps.length - 1)
        this.paymentStep.set(true);
      else
        this.paymentStep.set(false);
    });

    setTimeout(() => {
      this.webSvc.setShop(false);
    });
    this.sidebarSvc.close();
    this.initAuth();
  }

  calcValue(percent: number) {
    let tip: number;
    let sum = 0;

    if (this.cartExtended != null) {
      sum += this.cartExtended.getTotalItemsDiscountedAmount();
    }

    tip = sum * percent / 100;

    return tip;
  }

  setTip(evt: MatButtonToggleChange) {

    this.saveTip();
    this.cartExtended.tip.isCustom = evt.value == 0;

    if (this.cartExtended.tip.isCustom)
      this.tipGroup.controls['tip'].enable();
    else
      this.tipGroup.controls['tip'].disable();
  }

  setCustomTip() {
    this.cartExtended.tip.isCustom = true;
    this.tipGroup.controls['tip'].enable();
  }

  edit(item: OrderCartItemModel) {
    this.cartSvc.openDialog(new OrderCartItemModel(item), true);
  }

  remove(item: OrderCartItemModel) {
    this.cartSvc.removeFromCart(item);
  }

  createOrder() {
    if (this.paying() || this.tipGroup.invalid) return;
    this.paying.set(true);

    const location = this.locSvc.getCurrentLocation();

    const isFreeOrder = this.cartExtended.getOrderTotal(location) === 0;

    if (isFreeOrder) {
      this.cartExtended.paymentInfo.paymentProvider = EnumPaymentProvider.Free;
    }

    if (this.cartExtended.paymentInfo.paymentProvider == EnumPaymentProvider.CreditCard) {
      var userData = this.authSvc.getUserData();

      this.stripe.confirmPayment({
          elements: this.paymentElement.elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                name: `${userData.firstName} ${userData.lastName}`,
                email: userData.email
              }
            }
          },
          redirect: 'if_required'
        }
      ).subscribe(result => {
        // this.paying.set(false);
        // console.log('Result', result);
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          // alert({ success: false, error: result.error.message });
          this.snack.open(result.error.message ?? "", 'Error');
          this.paying.set(false);

        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            this.snack.open("Order created successfully", 'Success');
            const response = this.orderSvc.create(result.paymentIntent);

            response.pipe(
              catchError((error, caught) => {
                this.snack.open("There was an error contacting our servers. Try again shortly", 'Error', {
                  announcementMessage: 'Announcement',
                  politeness: "assertive",
                  horizontalPosition: "center",
                  verticalPosition: "bottom",
                  duration: 5000
                });
                this.paying.set(false);
                throw caught;
              })
            ).subscribe(data => {
              this.orderResponse = data;
              this.processResponse(data);
            });
          }
        }

      });
    } else if (this.cartExtended.paymentInfo.paymentProvider == EnumPaymentProvider.PayOnPickup) {
      this.cartExtended.user = new UserModel(this.authSvc.getUserdata());
      const response = this.orderSvc.createPop(this.cartExtended);

      response.pipe(
        catchError((error, caught) => {
          this.snack.open("There was an error contacting our servers. Try again shortly", 'Error', {
            announcementMessage: 'Announcement',
            politeness: "assertive",
            horizontalPosition: "center",
            verticalPosition: "bottom",
            duration: 5000
          });
          this.paying.set(false);
          throw caught;
        })
      ).subscribe(data => {
        this.orderResponse = data;
        this.processResponse(data);
      });
    } else {
      this.cartExtended.user = new UserModel(this.authSvc.getUserdata());
      const response = this.orderSvc.createFree(this.cartExtended);

      response.pipe(
        catchError((error, caught) => {
          this.snack.open("There was an error contacting our servers. Try again shortly", 'Error', {
            announcementMessage: 'Announcement',
            politeness: "assertive",
            horizontalPosition: "center",
            verticalPosition: "bottom",
            duration: 5000
          });
          this.paying.set(false);
          throw caught;
        })
      ).subscribe(data => {
        this.orderResponse = data;
        this.processResponse(data);
      });
    }
  }

  saveCurbside() {
    Object.assign(this.cartExtended.curbsideInfo, this.curbSideGroup.value);
  }

  saveTip() {
    if (this.tipGroup.valid)
      Object.assign(this.cartExtended.tip, this.tipGroup.value);
  }

  saveTipWithPayment() {
    if (this.tipGroup.invalid || this.addressSelectionGroup.invalid || this.curbSideGroup.invalid)
      return;
    this.saveTip();
  }

  getPaymentIntent() {
    this.cartExtended.authData = this.sessionSvc.getCurrentSS();
    const location = this.locSvc.getCurrentLocation();

    const isFreeOrder = this.cartExtended.getOrderTotal(location) === 0;

    if (isFreeOrder) {
      this.cartExtended.paymentInfo.paymentProvider = EnumPaymentProvider.Free;
    } else {
      this.orderSvc.getPaymentIntent(this.cartExtended).subscribe(data => {
        if (data != null && data.status == EnumOrderStatus.StripeOk) {
          // this.stripe = injectStripe(data.isTest ? environment.stripePkTest : environment.stripePkLive);
          // this.stripe = injectStripe(data.publishableKey);
          this.elementsOptions.clientSecret = data.clientSecret;
          // console.log('Client Secret', data.clientSecret);
          if (this.elements) {
            // console.log('Fetching updates');
            this.elements.fetchUpdates();
          }
        } else {
          Sentry.withScope((scope)=>{
            scope.setExtra("cartData", this.cartExtended);
            scope.setExtra("response", data);
            scope.setTag("location.Id", location.id);
            scope.setTag('website.id', location.websiteId);
            scope.captureException(new Error('PaymentIntent Error'));
          });
        }
      });
    }
  }

  curbSideChange(evt: MatCheckboxChange) {
    if (evt.checked) {
      this.curbSideGroup.controls['vehicleMake'].enable();
      this.curbSideGroup.controls['vehicleColor'].enable();
    } else {
      this.curbSideGroup.controls['vehicleMake'].disable();
      this.curbSideGroup.controls['vehicleColor'].disable();
    }
  }

  changeStep(evt: number) {
    // console.log(evt);
  }


  saveDelivery() {
    const isDifferent = this.cartExtended.selectedAddressId != this.addressSelectionGroup.controls['selectedAddressId'].value;

    this.cartExtended.selectedAddressId = this.addressSelectionGroup.controls['selectedAddressId'].value;
    if (isDifferent)
      this.cartSvc.save(this.cartExtended);
  }

  editAddress(adr: OrderUserAddressModel) {
    const dialogRef = this.modal.open(AddressDialogComponent, {
      data: adr,
      minWidth: '400px',
      autoFocus: 'first-heading'
    });

    dialogRef.afterClosed().subscribe(data => {

    })
  }

  getTaxesAndFeesTooltip() {
    const location = this.locSvc.getCurrentLocation();
    return Utils.getTaxesAndFeedTooltip(this.cartExtended, location);
  }

  removeDiscountCode(dc: string) {
    this.cartSvc.removeDiscount(this.cartExtended, dc);
  }

  applyDiscount() {
    if (this.discountGroup.invalid) return;

    this.cartSvc.applyDiscount(this.cartExtended, this.discountGroup.controls['discountCode'].value);
    this.discountGroup.patchValue({discountCode: ''});
  }

  public isPaymentStep() {
    return this.stepMaster.selectedIndex < this.stepMaster.steps.length - 1;
  }

  private processResponse(data: OrderResponseModel) {
    if (data == null) {
      this.snack.open("There was an error contacting our servers. Try again shortly", 'Error', {
        announcementMessage: 'Announcement',
        politeness: "assertive",
        horizontalPosition: "center",
        verticalPosition: "bottom",
        duration: 5000
      });
    } else {
      switch (data.status) {
        case EnumOrderStatus.Error: {
          this.snack.open(data.errorMessage, 'Error', {
            announcementMessage: 'Announcement',
            politeness: "assertive",
            horizontalPosition: "center",
            verticalPosition: "bottom",
            duration: 5000
          });
          break;
        }
        case EnumOrderStatus.New: {
          this.snack.open("Order created successfully!", "success", {
            politeness: "polite",
            verticalPosition: "bottom",
            horizontalPosition: "center",
            duration: 2500
          });
          this.authSvc.resetUser();
          this.router.navigate(['..', 'track-order', data.token], {relativeTo: this.route});
          break;
        }
      }
    }
  }

  private initForms() {
    this.curbSideGroup.patchValue(this.cartExtended.curbsideInfo);
    this.tipGroup.patchValue(this.cartExtended.tip);
    this.addressSelectionGroup.patchValue(this.cartExtended);

    if (this.cartExtended.tip.isCustom)
      this.tipGroup.controls['tip'].enable();
    else
      this.tipGroup.controls['tip'].disable();
  }

  private initAuth() {
    this.sessionSvc.isAuth$.subscribe(data => {
      if (data) {
        this.stepMaster.next();
      }
    });
  }
}
