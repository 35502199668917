<div class="mt-2">
  @if (currentAuthResponse$|async; as responseData) {
    @if (responseData.responseType != 0 && !!responseData.message) {
      <div class="bs-callout bs-callout-warn">
        {{ responseData.message }}
      </div>
    }
  }

  @switch (currentStep$|async) {
    @case (0) {
      <form [formGroup]="infoGroup">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput required type="text">
          <mat-icon *ngIf="!infoGroup.controls['email'].valid && infoGroup.controls['email'].touched" color="warn" matSuffix>warning</mat-icon>
          <mat-error *ngIf="infoGroup.controls['email'].errors?.['required']">required</mat-error>
          <mat-error *ngIf="infoGroup.controls['email'].errors?.['email']">invalid email format</mat-error>
        </mat-form-field>
      </form>
      <button (click)="auth()" [disabled]="!infoGroup.controls['email'].valid" color="primary" mat-flat-button>Continue</button>
    }
    @case (1) {

      @if (currentAuthResponse$|async; as responseData) {
        @if (responseData.isEmail) {
          <div>
            <h3 class="fw-bold">OTP Verification</h3>
            <p class="mat-caption">An email has been sent to your inbox ({{ responseData.entity }}) from <span class="fw-bold fst-italic">no-reply&#64;swiftserve.io</span>
              containing a
              6 digit
              verification code.
              <br/>
              For your security, this code will expire in
              <b>{{ (timeLeft$|async)?.[0] || 0 }}:{{ (timeLeft$|async)?.[1]|number:'2.0-0' }}</b></p>
            <div class="mb-3">
              <ng-otp-input (onInputChange)="otpChange($event)" [config]="{length: 6, allowNumbersOnly: true}"></ng-otp-input>
            </div>
            <button (click)="verify()" [disabled]="otp == null || otp.length <6" color="primary" mat-flat-button class="me-2">Verify</button>
            @if (timeLeft$|async; as timeLeft) {
              <button mat-stroked-button (click)="resendOtp()" [disabled]="timeLeft[0]>9 || (timeLeft[0]===9 && timeLeft[1]>30) || (timeLeft[0]===0 && timeLeft[1]===0)">
                @if (timeLeftResend$|async; as timeLeftResend) {
                  @if (timeLeftResend > 0) {
                    Resend in {{ timeLeftResend|number }}
                  }
                } @else {
                  Resend OTP
                }
              </button>
            }

          </div>
        } @else {
          <div>
            <h3 class="fw-bold">OTP Verification</h3>
            <p class="mat-caption">An SMS has been sent to your phone {{ responseData.entity }} containing a 6 digit verification code.
              <br/>
              For your security, this code will expire in
              <b>{{ (timeLeft$|async)?.[0] || 0 }}:{{ (timeLeft$|async)?.[1]|number:'2.0-0' }}</b></p>
            <div class="mb-3">
              <ng-otp-input (onInputChange)="otpChange($event)" [config]="{length: 6}"></ng-otp-input>
            </div>
            <button (click)="verify()" [disabled]="otp == null || otp.length <6" color="primary" mat-flat-button class="me-2">Verify</button>
            @if (timeLeft$|async; as timeLeft) {
              <button mat-stroked-button (click)="resendOtp()" [disabled]="timeLeft[0]>9 || (timeLeft[0]===9 && timeLeft[1]>30) || (timeLeft[0]===0 && timeLeft[1]===0)">
                @if (timeLeftResend$|async; as timeLeftResend) {
                  @if (timeLeftResend > 0) {
                    Resend in {{ timeLeftResend|number:'2.0-0' }}
                  }
                }
                @else {
                  Resend OTP
                }
              </button>
            }
          </div>
        }
      }

    }
    @case (2) {
      @if (currentUserData$|async; as userData) {
        @if (isStringNotEmptyOrWhitespace(userData.email)) {
          <div>
            <h4>{{ userData.firstName }} {{ userData.lastName }}
              <button (click)="reset()" mat-button color="primary" matTooltip="Change user">
                Change User
              </button>
            </h4>
            <span>{{ userData.email }} - {{ userData.phone|mask:'(999) 999-9999' }}</span>
          </div>
        }
      }
    }
    @case (3) {
      <div>
        <h3 class="fw-bold">Tell us about yourself</h3>
        <form [formGroup]="infoGroup">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>First Name</mat-label>
            <input formControlName="firstName" matInput required type="text">
            <mat-icon *ngIf="!infoGroup.controls['firstName'].valid && infoGroup.controls['firstName'].touched" color="warn" matSuffix>warning</mat-icon>
            <mat-error *ngIf="infoGroup.controls['firstName'].errors?.['required']">required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Last Name</mat-label>
            <input formControlName="lastName" matInput required type="text">
            <mat-icon *ngIf="!infoGroup.controls['lastName'].valid && infoGroup.controls['lastName'].touched" color="warn" matSuffix>warning</mat-icon>
            <mat-error *ngIf="infoGroup.controls['lastName'].errors?.['required']">required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput required type="text">
            <mat-icon *ngIf="!infoGroup.controls['email'].valid && infoGroup.controls['email'].touched" color="warn" matSuffix>warning</mat-icon>
            <mat-error *ngIf="infoGroup.controls['email'].errors?.['required']">required</mat-error>
            <mat-error *ngIf="infoGroup.controls['email'].errors?.['email']">invalid email format</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Phone</mat-label>
            <input formControlName="phone" mask="(000) 000-0000" matInput type="text">
            <mat-icon *ngIf="!infoGroup.controls['phone'].valid && infoGroup.controls['phone'].touched" color="warn" matSuffix>warning</mat-icon>
            <mat-error *ngIf="infoGroup.controls['phone'].errors?.['required']">required</mat-error>
            <mat-error *ngIf="infoGroup.controls['phone'].errors?.['pattern']">must be a 10 digit number</mat-error>
          </mat-form-field>
          <div class="mat-body-medium mb-2">
            By providing a mobile number, you give {{ (location$|async)?.friendlyName }} via Swift Serve permission to contact you using automated text
            messages to provide transactional messages
            such as order status updates.
          </div>
        </form>
        <button (click)="reset()" mat-button>Back</button>
        <button (click)="register()" [disabled]="!infoGroup.valid" class="mx-2" color="primary" mat-flat-button>Register</button>
      </div>
    }
  }
</div>


<!--<div [ngSwitch]="currentStep$|async" class="mt-2">-->
<!--  <ng-container *ngIf="currentAuthResponse$|async as responseData">-->
<!--    <div *ngIf="responseData.responseType != 0 && !!responseData.message " class="bs-callout bs-callout-warn">-->
<!--      {{ responseData.message }}-->
<!--    </div>-->
<!--  </ng-container>-->

<!--  &lt;!&ndash; This is the initial request for email or phone &ndash;&gt;-->
<!--  <ng-container *ngSwitchCase="0">-->
<!--    <form [formGroup]="infoGroup">-->
<!--      <mat-form-field appearance="outline" class="w-100">-->
<!--        <mat-label>Email</mat-label>-->
<!--        <input formControlName="email" matInput required type="text">-->
<!--        <mat-icon *ngIf="!infoGroup.controls['email'].valid && infoGroup.controls['email'].touched" color="warn" matSuffix>warning</mat-icon>-->
<!--        <mat-error *ngIf="infoGroup.controls['email'].errors?.['required']">required</mat-error>-->
<!--        <mat-error *ngIf="infoGroup.controls['email'].errors?.['email']">invalid email format</mat-error>-->
<!--      </mat-form-field>-->
<!--    </form>-->
<!--    <button (click)="auth()" [disabled]="!infoGroup.controls['email'].valid" color="primary" mat-raised-button>Continue</button>-->

<!--  </ng-container>-->
<!--  <ng-container *ngSwitchCase="1">-->
<!--    <div>-->
<!--      <h3 class="fw-bold">OTP Verification</h3>-->
<!--      <p class="mat-caption">An email has been sent to your inbox from <span class="fw-bold fst-italic">no-reply&#64;swiftserve.io</span> containing a 6 digit verification code.-->
<!--        <br/>-->
<!--        For your security, this code will expire in-->
<!--        <b>{{ (timeLeft$|async)?.[0] || 0 }}:{{ (timeLeft$|async)?.[1]|number:'2.0-0' }}</b></p>-->
<!--      <div class="mb-3">-->
<!--        <ng-otp-input (onInputChange)="otpChange($event)" [config]="{length: 6}"></ng-otp-input>-->
<!--      </div>-->
<!--      <button (click)="verify()" [disabled]="otp == null || otp.length <6" color="primary" mat-raised-button>Verify</button>-->
<!--    </div>-->
<!--  </ng-container>-->
<!--  <ng-container *ngSwitchCase="2">-->
<!--    <ng-container *ngIf="currentUserData$|async as userData">-->
<!--      <div *ngIf="userData.email != '' && userData.email != null">-->
<!--        <h4>{{ userData.firstName }} {{ userData.lastName }}-->
<!--          <button (click)="reset()" mat-button color="primary" matTooltip="Change user">-->
<!--            Change User-->
<!--          </button>-->
<!--        </h4>-->
<!--        <span>{{ userData.email }} - {{ userData.phone|mask:'(999) 999-9999' }}</span>-->
<!--      </div>-->
<!--    </ng-container>-->

<!--  </ng-container>-->
<!--  <ng-container *ngSwitchCase="3">-->
<!--    <div>-->
<!--      <h3 class="fw-bold">Tell us about yourself</h3>-->
<!--      <form [formGroup]="infoGroup">-->
<!--        <mat-form-field appearance="outline" class="w-100">-->
<!--          <mat-label>First Name</mat-label>-->
<!--          <input formControlName="firstName" matInput required type="text">-->
<!--          <mat-icon *ngIf="!infoGroup.controls['firstName'].valid && infoGroup.controls['firstName'].touched" color="warn" matSuffix>warning</mat-icon>-->
<!--          <mat-error *ngIf="infoGroup.controls['firstName'].errors?.['required']">required</mat-error>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field appearance="outline" class="w-100">-->
<!--          <mat-label>Last Name</mat-label>-->
<!--          <input formControlName="lastName" matInput required type="text">-->
<!--          <mat-icon *ngIf="!infoGroup.controls['lastName'].valid && infoGroup.controls['lastName'].touched" color="warn" matSuffix>warning</mat-icon>-->
<!--          <mat-error *ngIf="infoGroup.controls['lastName'].errors?.['required']">required</mat-error>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field appearance="outline" class="w-100">-->
<!--          <mat-label>Email</mat-label>-->
<!--          <input formControlName="email" matInput required type="text">-->
<!--          <mat-icon *ngIf="!infoGroup.controls['email'].valid && infoGroup.controls['email'].touched" color="warn" matSuffix>warning</mat-icon>-->
<!--          <mat-error *ngIf="infoGroup.controls['email'].errors?.['required']">required</mat-error>-->
<!--          <mat-error *ngIf="infoGroup.controls['email'].errors?.['email']">invalid email format</mat-error>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field appearance="outline" class="w-100">-->
<!--          <mat-label>Phone</mat-label>-->
<!--          <input formControlName="phone" mask="(000) 000-0000" matInput type="text">-->
<!--          <mat-icon *ngIf="!infoGroup.controls['phone'].valid && infoGroup.controls['phone'].touched" color="warn" matSuffix>warning</mat-icon>-->
<!--          <mat-error *ngIf="infoGroup.controls['phone'].errors?.['required']">required</mat-error>-->
<!--          <mat-error *ngIf="infoGroup.controls['phone'].errors?.['pattern']">must be a 10 digit number</mat-error>-->
<!--        </mat-form-field>-->
<!--        <div class="mat-body-2 mb-2">-->
<!--          By providing a mobile number, you give {{ (location$|async)?.friendlyName }} via Swift Serve permission to contact you using automated text-->
<!--          messages to provide transactional messages-->
<!--          such as order status updates.-->
<!--        </div>-->
<!--      </form>-->
<!--      <button (click)="reset()" mat-button>Back</button>-->
<!--      <button (click)="register()" [disabled]="!infoGroup.valid" class="mx-2" color="primary" mat-raised-button>Register</button>-->
<!--    </div>-->
<!--  </ng-container>-->
<!--</div>-->
