import {guidEmpty, isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class AuthStorageModel {
  public isAuth: boolean = false;
  public token: Nullable<string> = null;
  public isEmail: boolean = false;
  public id: string = guidEmpty;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.token))
      this.token = data.token;
    if (data.isAuth != null)
      this.isAuth = data.isAuth;
    if (data.isEmail != null)
      this.isEmail = data.isEmail;
    if (isStringNotEmptyOrWhitespace(data.id))
      this.id = data.id;
  }
}

