import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, Observable} from "rxjs";
import {WebsiteService} from "../services/website.service";
import {isStringNotEmpty} from "../utils/Utils";
import {LoadingService} from "../services/loading.service";
import {map} from "rxjs/operators";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private service: WebsiteService, private loadSvc: LoadingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleAccess(req, next);
  }

  private handleAccess(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.service.websiteIdentifier;
    this.loadSvc.setLoading(true, request.url);

    const headerSettings: { [name: string]: string | string[]; } = {};
    for (const key of request.headers.keys()) {
      // @ts-ignore
      headerSettings[key] = request.headers.getAll(key);
    }
    if (isStringNotEmpty(token)) {
      headerSettings['R-Shop'] = token;
    }
    headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(headerSettings);
    const changedReq = request.clone({headers: newHeader});
    return next.handle(changedReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loadSvc.setLoading(false, request.url);
        }
        return event;
      }),
      catchError((error) => {
        this.loadSvc.setLoading(false, request.url);
        throw error;
      })
    );
  }
}
