<div class="container-ss-md" style="position: relative">
  <div class="overlay" *ngIf="isLoadingAddress">
    <mat-spinner class="spinner" color="primary"></mat-spinner>
  </div>

  <!--  <ng-container *ngIf="isStringNotEmptyGuid(location.id)">-->
  <form #f='ngForm'>
    <mat-card appearance="outlined" class="mt-4">
      <mat-card-header>
        <mat-card-title class="mt-4 mt-sm-0">
          <div style="font-size: 2.5em; line-height: 1.2em; font-weight: 500;">Checkout</div>
        </mat-card-title>
        <mat-card-subtitle class="mb-2">
          @if (location$|async; as location) {
            @if (isStringNotEmptyGuid(location.id)) {
              <div class="mat-headline-6 mb-1">{{ location.friendlyName }}</div>
              <div>{{ location.street1 }},@if (!!location.street2) {
                {{ location.street2 }},
              } {{ location.city }}, {{ location.state }} {{ location.zip }}
              </div>
            }
          }
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="mat-body-1">
        <app-order-type-info alignmentCss="justify-content-center"></app-order-type-info>
        <mat-divider></mat-divider>
        <!--        <pre>{{cartExtended|json}}</pre>-->
        <mat-stepper #stepMaster (selectedIndexChange)="changeStep($event)" [linear]="true" color="primary"
                     [orientation]="(isSmall$|async)?'horizontal':'vertical'">
          <mat-step [completed]="(sessionSvc.isAuth$|async)??false" [label]="(isSmall$|async)?'':'Your information'">
            @if (isSmall$|async) {
              <h5 class="text-center">Your information</h5>
            }
            <app-auth></app-auth>
            <div *ngIf="sessionSvc.isAuth$|async" class="mt-3">
              <button mat-flat-button color="primary" matStepperNext>Next</button>
            </div>
          </mat-step>


          @if (location$|async; as location) {
            @if (location.enableCurbsidePickup) {
              <mat-step [stepControl]="curbSideGroup" [label]="(isSmall$|async)?'':'Curbside Pickup'">
                @if (isSmall$|async) {
                  <h5 class="text-center">Tip</h5>
                }
                <form [formGroup]="curbSideGroup">
                  <mat-checkbox (change)="curbSideChange($event)" formControlName="isCurbsidePickup">Bring my order to my
                    car
                  </mat-checkbox>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Vehicle Make/Model</mat-label>
                    <input formControlName="vehicleMake" matInput required type="text">
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Vehicle Color</mat-label>
                    <input formControlName="vehicleColor" matInput type="text">
                  </mat-form-field>
                </form>
                <div>
                  <button mat-stroked-button matStepperPrevious class="secondary-button">Back</button>
                  <button (click)="saveCurbside()" mat-flat-button color="primary" class="mx-2" matStepperNext>Next</button>
                </div>
              </mat-step>
            }
          }
          <mat-step [label]="(isSmall$|async)?'':'Your Order ('+cartExtended.getTotalItemsCount()+')'">
            <table class="cart-table">
              <tbody>
              <tr *ngFor="let item of cartExtended.items; index as i;" role="group">
                <td class="cart-item-q">{{ item.quantity }}</td>
                <td class="cart-item-n">
                  <span class="item-name">{{ item.name }}</span>
                  @if (item.modifiers.length > 0) {
                    <div class="text-muted">

                      <b>Items:</b>
                      <ul class="cart-modifiers">
                        <li *ngFor="let mod of item.modifiers; last as isLast" class="cart-modifier-checkout text-muted">
                          <span class="modifier-quantity">{{ mod.quantity }}</span>
                          {{ mod.name }}
                        </li>
                      </ul>
                    </div>
                  }
                  @if (item.specialInstructions != null && item.specialInstructions.length > 0) {
                    <div class="text-muted mt-2">
                      <b>Instructions:</b><br/>
                      {{ item.specialInstructions }}
                    </div>
                  }


                </td>
                <td class="cart-item-p">
                  @if (item.discountAmount > 0) {
                    <span class="text-strike">{{ item.getTotal()|currency }}</span>
                    {{ item.getDiscountedTotal()|currency }}
                  } @else {
                    {{ item.getTotal()|currency }}
                  }
                </td>
              </tr>
              </tbody>
            </table>
            <div>
              <button mat-stroked-button matStepperPrevious class="secondary-button">Back</button>
              <button mat-flat-button color="primary" class="mx-2" matStepperNext>Next</button>
            </div>
          </mat-step>
          <mat-step [stepControl]="tipGroup" [label]="(isSmall$|async)?'':'Tip'">
            <form [formGroup]="tipGroup">
              @if (isSmall$|async) {
                <h5 class="text-center">Tip</h5>
              }
              <mat-button-toggle-group (change)="setTip($event)" class="w-100" formControlName="tipPercent" [hideSingleSelectionIndicator]="true"
                                       name="tipSelector" appearance="standard">
                <mat-button-toggle [value]="0.22" class="w-100">
                  <div class="mat-text-primary">22%</div>
                  {{ calcValue(22)|currency }}
                </mat-button-toggle>
                <mat-button-toggle [value]="0.2" class="w-100">
                  <div class="mat-text-primary">20%</div>
                  {{ calcValue(20)|currency }}
                </mat-button-toggle>
                <mat-button-toggle [value]="0.15" class="w-100">
                  <div class="mat-text-primary">15%</div>
                  {{ calcValue(15)|currency }}
                </mat-button-toggle>
                <mat-button-toggle (change)="setCustomTip()" [value]="0" class="w-100">
                  <div class="mat-text-primary">Custom</div>
                  <!--                    {{ cartExtended.tip.tipPercent ?? 0|currency }}-->
                  &nbsp;
                </mat-button-toggle>
              </mat-button-toggle-group>
              <mat-form-field appearance="outline" class="w-100 mt-2">
                <mat-label>Custom Amount</mat-label>
                <input formControlName="tip" matInput type="number">
                <mat-icon matPrefix>attach_money</mat-icon>
              </mat-form-field>
            </form>
            <div>
              <button mat-stroked-button matStepperPrevious class="secondary-button">Back</button>
              <button (click)="saveTipWithPayment()" mat-flat-button color="primary" class="mx-2" matStepperNext>Next</button>
            </div>
          </mat-step>
          <mat-step [label]="(isSmall$|async)?'':'Discount code(s)'">
            @if (isSmall$|async) {
              <h5 class="text-center">Discount Code(s)</h5>
            }
            @if (Object.keys(cartExtended.discountCodes); as keys) {
              @if (keys.length > 0) {
                <div class="mt-3">
                  <div *ngFor="let dc of keys">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>{{ dc }}</div>
                      <div>
                        <button (click)="removeDiscountCode(dc)" mat-icon-button>
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            }

            <form [formGroup]="discountGroup">
              <div class="row">
                <div class="col-11">
                  <mat-form-field appearance="outline" class="w-100 mt-3">
                    <mat-label>Discount Code</mat-label>
                    <input formControlName="discountCode" matInput type="text">
                  </mat-form-field>
                </div>
                <div class="col-1 d-flex align-items-center justify-content-center">
                  <!--                  <button type="button" mat-icon-button (click)="applyDiscount()" [disabled]="discountGroup.invalid">-->
                  <!--                    <mat-icon>add</mat-icon>-->
                  <!--                  </button>-->
                  <button type="button" mat-button (click)="applyDiscount()" [disabled]="discountGroup.invalid" color="primary">
                    Apply
                  </button>
                </div>
              </div>
            </form>
            <div>
              <button mat-stroked-button matStepperPrevious class="secondary-button">Back</button>
              <button mat-flat-button color="primary" class="mx-2" matStepperNext>Next</button>
            </div>
          </mat-step>
          @if (location$|async; as location) {
            <mat-step [label]="(isSmall$|async)?'':'Payment'">
              <mat-button-toggle-group [(ngModel)]="cartExtended.paymentInfo.paymentProvider" class="w-100 my-3" [hideSingleSelectionIndicator]="true"
                                       name="paymentSelector">
                <mat-button-toggle [value]="EnumPaymentProvider.PayOnPickup" class="w-100"
                                   [disabled]="!location.enablePayOnPickup || cartExtended.orderType == EnumOrderType.Delivery || cartExtended.paymentInfo.paymentProvider === EnumPaymentProvider.Free">
                  <mat-icon>attach_money</mat-icon>
                  Pay on pickup
                </mat-button-toggle>
                <mat-button-toggle [value]="EnumPaymentProvider.CreditCard" class="w-100"
                                   [disabled]="!location.enablePayWithCreditCard || cartExtended.paymentInfo.paymentProvider === EnumPaymentProvider.Free">
                  <mat-icon>credit_card</mat-icon>
                  Credit Card
                </mat-button-toggle>
                @if (cartExtended.paymentInfo.paymentProvider === EnumPaymentProvider.Free) {
                  <mat-button-toggle [value]="EnumPaymentProvider.Free" class="w-100">
                    <mat-icon>redeem</mat-icon>
                    Free
                  </mat-button-toggle>
                }
              </mat-button-toggle-group>
              @if (cartExtended.paymentInfo.paymentProvider == EnumPaymentProvider.CreditCard) {
                @if (isStringNotEmptyOrWhitespace(elementsOptions.clientSecret)) {
                  <ngx-stripe-elements [elementsOptions]="elementsOptions" [stripe]="stripe"  #sElements>
                    <ngx-stripe-payment [options]="paymentElementOptions"></ngx-stripe-payment>
                  </ngx-stripe-elements>
                }
              }
              <div>
                <button mat-stroked-button class="mt-3 secondary-button" matStepperPrevious>Back</button>
              </div>
            </mat-step>
          }
        </mat-stepper>

        <mat-divider class="mb-2"></mat-divider>
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span>Subtotal</span>
          <span>{{ cartExtended.getTotalItemsAmount()|currency }}</span>
        </div>
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span>Discount</span>
          <span>{{ cartExtended.getTotalDiscountAmount()|currency }}</span>
        </div>
        @if (location$|async; as location) {
          @if (cartExtended.getOrderTotal(location) == 0) {
            <div class="d-flex w-100 justify-content-between align-items-center">
              <span>Taxes & Fees</span>

              <span>{{ 0|currency }}</span>
            </div>
          } @else {
            <div class="d-flex w-100 justify-content-between align-items-center">
            <span>Taxes & Fees
              <fa-icon [icon]="faInfoCircle"
                       [matTooltip]="getTaxesAndFeesTooltip()"></fa-icon></span>

              <span>{{ (cartExtended.getTotalItemsDiscountedAmount() * location.taxRate() + location.customFee)|currency }}</span>
            </div>
          }



          <div class="d-flex w-100 justify-content-between align-items-center">
            <span>Tip</span>
            <span>{{ cartExtended.getOrderTip()|currency }}</span>
          </div>
          <div class="d-flex w-100 justify-content-between align-items-center"
               *ngIf="cartExtended.orderType === EnumOrderType.Delivery">
            <span>Delivery Fee</span>
            <span>@if (cartExtended.freeDeliveryDiscount) {
              Free
            } @else {
              {{ cartExtended.deliveryFee|currency }}
            }</span>
          </div>
          <div class="d-flex w-100 justify-content-between align-items-center">
            <span>Total</span>
            <span>{{ cartExtended.getOrderTotalWithFees(location) |currency }}</span>
          </div>
        }
      </mat-card-content>
    </mat-card>
    <button (click)="createOrder()" [disabled]="!f.form.valid || !paymentStep() || paying() || !isStringNotEmptyOrWhitespace(elementsOptions.clientSecret)" class="w-100 mt-1 mb-4"
            color="primary" mat-flat-button>
      @if (!paying()) {
        Submit
      } @else {
        <mat-spinner diameter="20"></mat-spinner>
      }
    </button>
  </form>

  <!--  <pre>f.form.valid = {{f.form.valid}}</pre>-->
  <!--  <pre> stepMaster.selectedIndex = {{ stepMaster.selectedIndex}}</pre>-->
  <!--  <pre> stepMaster.steps.length-1 = {{stepMaster.steps.length-1}}</pre>-->

  <a *ngIf="location$|async as location;" [routerLink]="['..','l', location.id]" class="back-to-menu-link"
     color="primary" mat-button>Back to Menu</a>

</div>

