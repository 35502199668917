import {OrderItemPriceModel} from "../OrderItemPriceModel";
import {OrderCartItemModifierModel} from "./OrderCartItemModifierModel";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../../utils/Utils";
import {BaseModelInt} from "../BaseModelInt";

export class OrderCartItemModel extends BaseModelInt {

  public token: string = '';
  public name: string = '';
  public price: OrderItemPriceModel = new OrderItemPriceModel();
  public quantity: number = 1;
  public totalPrice: number = 0;
  public modifiers: OrderCartItemModifierModel[] = [];
  public specialInstructions: string = '';
  public discountAmount: number = 0;
  public discountCode: string = '';
  public cartItemId: number = 0;

  constructor(data: any = null) {
    super(data);

    if (data == null)
      return;
    if (isStringNotEmptyGuid(data.token))
      this.token = data.token;
    if (isStringNotEmptyOrWhitespace(data.name))
      this.name = data.name;
    if (data.price != null)
      this.price = new OrderItemPriceModel(data.price);
    if (data.quantity != null && !isNaN(parseInt(data.quantity, 10)))
      this.quantity = parseInt(data.quantity, 10);
    if (data.totalPrice != null && !isNaN(parseFloat(data.totalPrice)))
      this.totalPrice = parseFloat(data.totalPrice);
    if (data.modifiers != null && Array.isArray(data.modifiers) && data.modifiers.length > 0)
      for (let item of data.modifiers) this.modifiers.push(new OrderCartItemModifierModel(item));
    if (isStringNotEmptyOrWhitespace(data.specialInstructions))
      this.specialInstructions = data.specialInstructions;
    if (data.discountAmount != null && !isNaN(parseFloat(data.discountAmount)))
      this.discountAmount = parseFloat(data.discountAmount);
    if (isStringNotEmptyOrWhitespace(data.discountCode))
      this.discountCode = data.discountCode;
    if (data.cartItemId != null && !isNaN(parseInt(data.cartItemId, 10)))
      this.cartItemId = parseInt(data.cartItemId, 10);

  }

  getTotal() {
    let singlePrice = (this.price.price ?? 0);

    if (this.modifiers.length > 0) {
      for (let modifier of this.modifiers) {
        singlePrice += modifier.getTotal();
      }
    }

    return singlePrice * this.quantity;
  }

  getDiscountedTotal() {
    let singlePrice = (this.price.price ?? 0) - this.discountAmount;

    if (this.modifiers.length > 0) {
      for (let modifier of this.modifiers) {
        singlePrice += modifier.getTotal();
      }
    }

    return singlePrice * this.quantity;
  }
}
