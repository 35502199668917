<app-location-info *ngIf="location$|async as data;" [location]="data"></app-location-info>
<div class="top-menu-categories">
  <mat-toolbar class="mat-elevation-z4" color="primary">
    <ng-scrollbar #scrollbar [autoWidthDisabled]="true" appearance="compact"
                  style="height: 50px; width: 100%; margin-bottom: -8px;" track="horizontal"
                  visibility="native">
      <div class="categories-wrapper d-flex align-items-center justify-content-center">
        <span class="mr-2" style="font-size: 1rem;">Menu:</span>
        <ng-container *ngFor="let item of (menus$|async)?.menus">
          <button (click)="scrollToAnchorMenu(item)" *ngIf="item.canDisplay()" [focusOnMe]="true" [focusScrollbar]="scrollbar"
                  [spyOn]="getMenuId(item)"
                  activeClass="active-category" class="mx-2" mat-button>{{ item.name }}
          </button>
        </ng-container>
      </div>
    </ng-scrollbar>
  </mat-toolbar>
</div>
<div>
  @for (item of (menus$|async)?.menus; track item) {
    @if (item.canDisplay()) {
      <section [id]="getMenuId(item)" [spyTarget]="getMenuId(item)" style="padding: 30px 0 0 0;">
        <mat-card appearance="raised" class="mb-3 bg-accent-color mat-elevation-z8">
          <mat-card-header>
            <mat-card-title class="mat-headline-5 uppercase">{{ item.name }}
              <ng-container *ngIf="!item.isAvailableAllTimes">
                ({{ item.getTimeAvailabilityString() }})
              </ng-container>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content *ngIf="item.description!=null && item.description != ''" class="mat-body-medium">
            <div [innerHTML]="item.description">
            </div>
          </mat-card-content>
        </mat-card>


        <ng-container *ngIf="item.hasProducts then prodTemplate; else subMenuTemplate;">
        </ng-container>
        <ng-template #prodTemplate>
          <div class="row row-cols-1 row-cols-lg-2  align-items-stretch g-2">
            <app-menu-item *ngFor="let item of item.items" [product]="item" class="col"></app-menu-item>
          </div>
        </ng-template>
        <ng-template #subMenuTemplate>
          <ng-container *ngFor="let subMenu of item.subMenus">
            <div class="mb-3">
              <p class="mat-headline-6 uppercase">{{ subMenu.name }}</p>
              <div *ngIf="subMenu.description!=null && subMenu.description != ''" [innerHTML]="subMenu.description" class="mat-body-1">
              </div>
              <div *ngIf="subMenu.items != null && subMenu.items.length>0" class="row row-cols-1 row-cols-lg-2  align-items-stretch g-3">
                <app-menu-item *ngFor="let product of subMenu.items" [product]="product" class="col"></app-menu-item>
              </div>
            </div>

          </ng-container>

        </ng-template>

      </section>
    }

  }
</div>
