export enum EnumOrderStatus {
  Error = -1,
  Ok = 0,
  New = 1,
  Confirmed,
  InProgress,
  ReadyForPickup,
  PickedUp,
  Delivered,
  StripeOk = 100
}

