<div class="order-card" [ngClass]="{'out-of-stock': !product.isAvailable}" (click)="openAddToCart()"
     disabled="disabled">
  <div class="order-card-left gap-1 flex-grow-1 flex-column">
    <div class="order-card-left-top">
      <div class="order-card-title d-flex justify-content-between align-items-center">
        <span>{{ product.name }}</span>
        <div>
          @for (lbl of product.labels; track lbl) {
            <mat-icon [svgIcon]="lbl"></mat-icon>
          }
        </div>
      </div>
      <p class="order-card-subtitle" [innerHTML]="product.description"></p>
    </div>
    <div class="order-card-left-bottom">
      <span class="order-card-price">{{ getPrice() }}</span>
      <span *ngIf="!product.isAvailable" class="ms-2">Out of stock</span>
    </div>
  </div>
  <div class="order-card-right">
    <img [src]="product.getImage('300x300')" class="order-card-image" alt="Product Image">
  </div>
  <div class="order-card-overlay d-flex align-items-center justify-content-center">
  </div>
</div>
