import {BaseModel} from "../BaseModel";
import {OrderCartItemModel} from "./OrderCartItemModel";
import {EnumOrderType} from "../enums/EnumOrderType";
import {guidEmpty, isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../../utils/Utils";
import {OrderUserAddressModel} from "./OrderUserAddressModel";


export class OrderCartModel extends BaseModel {
  public locationToken: string = guidEmpty;
  public websiteToken: string = guidEmpty;
  public orderType: EnumOrderType = EnumOrderType.PickUp;
  public orderDate: Nullable<Date> = null;
  public isAsap: boolean = true;
  public items: OrderCartItemModel[] = [];
  public addresses: OrderUserAddressModel[] = [];
  public selectedAddressId: string = guidEmpty;
  public isGuestUser: boolean = true;
  public deliveryFee: number = 0;
  public discountCodes: { [discountCode: string]: number } = {};
  public freeDeliveryDiscount: boolean = false;
  public amountFees: number = 0;
  public authUserToken: Nullable<string> = null;

  constructor(data: any = null) {
    super(data);

    this.fillData(data);
  }

  fillData(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyGuid(data.id))
      this.id = data.id;
    if (isStringNotEmptyGuid(data.locationToken))
      this.locationToken = data.locationToken;
    if (isStringNotEmptyGuid(data.websiteToken))
      this.websiteToken = data.websiteToken;
    if (data.orderType != null && !isNaN(parseInt(data.orderType)))
      this.orderType = parseInt(data.orderType);
    if (data.isAsap != null)
      this.isAsap = data.isAsap;
    if (data.orderDate != null) {
      this.orderDate = new Date(data.orderDate);
      this.isAsap = false;
    }
    if (data.items != null && Array.isArray(data.items) && data.items.length > 0) {
      this.items.splice(0, this.items.length);
      for (let item of data.items) this.items.push(new OrderCartItemModel(item));
    }
    if (data.addresses != null && Array.isArray(data.addresses) && data.addresses.length > 0) {
      this.addresses.splice(0, this.addresses.length);
      for (let address of data.addresses) this.addresses.push(new OrderUserAddressModel(address));
    }
    if (isStringNotEmptyGuid(data.selectedAddressId))
      this.selectedAddressId = data.selectedAddressId;
    if (data.isGuestUser != null)
      this.isGuestUser = data.isGuestUser;
    if (data.deliveryFee != null && !isNaN(parseFloat(data.deliveryFee)))
      this.deliveryFee = parseFloat(data.deliveryFee);
    if (data.discountCodes != null)
      this.discountCodes = data.discountCodes;
    if (data.freeDeliveryDiscount != null)
      this.freeDeliveryDiscount = data.freeDeliveryDiscount;
    if (isStringNotEmptyOrWhitespace(data.authUserToken))
      this.authUserToken = data.authUserToken;

    this.removeDuplicateAddresses();
  }

  removeDuplicateAddresses() {
    let uniqueAddresses: OrderUserAddressModel[] = [];
    let uniqueIds: string[] = [];
    for (let address of this.addresses) {
      if (!uniqueIds.includes(`${address.placeId}-${address.street2}`)) {
        uniqueAddresses.push(address);
        uniqueIds.push(`${address.placeId}-${address.street2}`);
      }
    }
    this.addresses = uniqueAddresses;
  }


  getTotalItemsCount() {
    let sum = 0;

    if (this.items != null && this.items.length > 0) {
      for (let item of this.items)
        sum += item.quantity;
    }

    return sum;
  }

  getTotalItemsAmount() {
    let sum = 0;

    if (this.items != null && this.items.length > 0) {
      for (let item of this.items)
        sum += item.getTotal();
    }

    return sum;
  }

  getTotalItemsDiscountedAmount() {
    let sum = 0;

    if (this.items != null && this.items.length > 0) {
      for (let item of this.items)
        sum += item.getDiscountedTotal();
    }

    return sum;
  }

  getTotalDiscountAmount() {
    let sum = 0;

    if (this.items != null && this.items.length > 0) {
      for (let item of this.items)
        sum += item.getTotal() - item.getDiscountedTotal();
    }

    return sum;
  }

  patchValue(data: any) {
    if (data == null)
      return;

    if (data.orderType != null && !isNaN(parseInt(data.orderType)))
      this.orderType = parseInt(data.orderType);
    if (data.isAsap != null)
      this.isAsap = data.isAsap;
    if (data.orderDate != null) {
      this.orderDate = new Date(data.orderDate);
      this.isAsap = false;
    }
    if (data.isGuestUser != null)
      this.isGuestUser = data.isGuestUser;
    if (isStringNotEmptyGuid(data.selectedAddressId))
      this.selectedAddressId = data.selectedAddressId;
  }
}

