import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {AuthStorageModel} from "../models/AuthStorageModel";

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private bsSessionStorage = new BehaviorSubject<AuthStorageModel>(new AuthStorageModel());
  public current$ = this.bsSessionStorage.asObservable();
  private bsIsAuth = new BehaviorSubject<boolean>(false);
  public isAuth$ = this.bsIsAuth.asObservable();

  private sub = new Subscription();

  constructor() {
    this.init();
  }

  public saveAuth() {
    const auth = this.bsSessionStorage.value;
    sessionStorage.setItem('ss-auth-data', JSON.stringify(auth));
    this.bsIsAuth.next(auth.isAuth);
  }

  public clearAuth() {
    sessionStorage.removeItem('ss-auth-data');
    this.bsSessionStorage.next(new AuthStorageModel());
    this.bsIsAuth.next(false);
  }

  public getCurrentSS() {
    return this.bsSessionStorage.value;
  }

  public saveToken(token: string, isEmail: boolean, id: string) {
    const ssO = new AuthStorageModel({id: id, token: token, isEmail: isEmail, isAuth: true});
    this.bsSessionStorage.next(ssO);
    this.saveAuth();
  }

  public isAuth(): boolean {
    return this.bsIsAuth.value;
  }

  private init() {
    const auth = sessionStorage.getItem('ss-auth-data');
    if (auth != null) {
      const ss= new AuthStorageModel(JSON.parse(auth));
      this.bsSessionStorage.next(ss);
      this.bsIsAuth.next(ss.isAuth);
    }
  }
}
