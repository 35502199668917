<div class="d-flex">
  <div matSnackBarLabel>{{ data.message }}
    @if (data.errors.length > 0) {
      <ul>
        <li *ngFor="let error of data.errors">{{ error }}</li>
      </ul>
    }
  </div>

  <div matSnackBarActions>
    <button type="button" mat-button matSnackBarAction (click)="close()">{{data.type}}</button>
  </div>
</div>
