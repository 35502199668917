import {SettingItemModel} from "./SettingItemModel";

export class SettingsModel {

  public items: SettingItemModel[] = [];

  constructor(data: any = null) {

    if (data == null)
      return;

    if (data.items != null && Array.isArray(data.items) && data.items.length>0)
    {
      for(const item of data.items)
        this.items.push(new SettingItemModel(item));
    }
  }
}

