import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, share, Subscription} from "rxjs";
import {HttpService} from "./base/http.service";
import {isStringNotEmpty} from "../utils/Utils";
import {map} from "rxjs/operators";
import {WebsiteModel} from "../models/WebsiteModel";
import {ApiEndpoints} from "../utils/ApiEndpoints";

@Injectable({
  providedIn: 'root'
})
export class WebsiteService implements OnDestroy {
  protected bsIsShop = new BehaviorSubject<boolean>(false);
  public readonly isShop$ = this.bsIsShop.asObservable();
  protected bsWebId = new BehaviorSubject<string>('');
  protected bsSettings = new BehaviorSubject<WebsiteModel>(new WebsiteModel());
  public readonly website$ = this.bsSettings.asObservable();
  private subSink: Subscription = new Subscription();

  constructor(private http: HttpService) {
  }

  public get websiteIdentifier() {
    return this.bsWebId.value;
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  public registerWebsite(webIdentifier: string) {
    if (isStringNotEmpty(webIdentifier))
      this.bsWebId.next(webIdentifier);
    else
      this.bsWebId.next('');
  }

  public execute() {
    const obs = this.http.get<WebsiteModel>(ApiEndpoints.website).pipe(map(f => new WebsiteModel(f)), share());
    this.subSink.add(obs.subscribe(data => {
      this.bsSettings.next(data);
    }));
    return obs;
  }

  public getCurrentWebsite() {
    return this.bsSettings.value;
  }

  public setShop(isShop: boolean) {
    this.bsIsShop.next(isShop);
  }

  public getIsShop(): boolean {
    return this.bsIsShop.value;
  }
}
