import {Injectable} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidenav!: MatSidenav;

  constructor() {
  }

  register(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  open() {
    if (this.sidenav != null)
      this.sidenav.open();
  }

  close() {
    if (this.sidenav != null)
      this.sidenav.close();
  }

  toggle() {
    if (this.sidenav != null)
      this.sidenav.toggle();
  }
}
