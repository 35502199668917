import {isStringNotEmptyOrWhitespace} from "../../utils/Utils";

export class OtpAuthUserBaseDataModel {
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public phone: string = '';

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.firstName))
      this.firstName = data.firstName;
    if (isStringNotEmptyOrWhitespace(data.lastName))
      this.lastName = data.lastName;
    if (isStringNotEmptyOrWhitespace(data.email))
      this.email = data.email;
    if (isStringNotEmptyOrWhitespace(data.phone))
      this.phone = data.phone;
  }
}
