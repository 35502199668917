import {isStringNotEmptyOrWhitespace} from "../../utils/Utils";

export class OrdersAuthRequestModel {
  public email: string = '';
  public phone: string = '';
  public isEmail: boolean = false;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.email))
      this.email = data.email;
    if (isStringNotEmptyOrWhitespace(data.phone))
      this.phone = data.phone;
    if (data.isEmail != null)
      this.isEmail = data.isEmail;
  }
}

export class OtpAuthRequestModel {
  constructor(public data: string = '',  public isEmail: boolean = false,  public clientOtp: string = '') {
  }
}
