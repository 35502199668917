import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {WebsiteModel} from "../../models/WebsiteModel";
import {WebsiteService} from "../../services/website.service";
import {DOCUMENT} from "@angular/common";
import {getMenuLinkUtil, isStringNotEmptyGuid} from "../../utils/Utils";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public website$: Observable<WebsiteModel>;
  public website!: WebsiteModel;
  protected readonly isStringNotEmptyGuid = isStringNotEmptyGuid;

  constructor(private websiteSvc: WebsiteService, @Inject(DOCUMENT) private document: Document, private sanitizer: DomSanitizer) {
    this.website$ = this.websiteSvc.website$;
  }

  ngOnInit(): void {
    this.website$.subscribe(data => {
        this.website = data;
      }
    );
  }

  getMenuLink(link: string, secondLevel: Nullable<string> = null) {
    return getMenuLinkUtil(link, secondLevel, this.website, this.sanitizer);
  }
}
