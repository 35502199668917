<div class="d-flex align-items-center justify-content-evenly flex-column flex-sm-row gap-2 mt-2">
  @if (location?.isOpen24Hours) {
    <mat-chip-set>
      <mat-chip color="primary" highlighted>Open</mat-chip>
    </mat-chip-set>
    <span class="mx-3">Open 24/7</span>
  } @else {
    <ng-container *ngIf="(isOpen$|async)">
      <mat-chip-set>
        <mat-chip color="primary" highlighted>Open</mat-chip>
      </mat-chip-set>
      <span class="mx-3" *ngIf="(hours$|async)?.isOpen">Closes at {{ getTime(true) }}</span>
      <span class="mx-3" *ngIf="!(hours$|async)?.isOpen">Closed</span>
    </ng-container>
    <ng-container *ngIf="!(isOpen$|async)">
      <mat-chip-listbox>
        <mat-chip color="warn" highlighted>Closed</mat-chip>
      </mat-chip-listbox>
      <span class="mx-3" *ngIf="(hours$|async)?.isOpen">Opens at {{ getTime(false) }}</span>
      <span class="mx-3" *ngIf="!(hours$|async)?.isOpen">Closed</span>
    </ng-container>
  }
  @if (showMoreInfo) {
    <a (click)="openMoreInfo()" mat-stroked-button matTooltip="Click here for more info" color="primary">More Info</a>
  }

</div>
