import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {WebsiteModel} from "../models/WebsiteModel";
import {WebsiteService} from "../services/website.service";

export const shopResolverFn: ResolveFn<WebsiteModel> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const webSvc = inject(WebsiteService);
  const webIdentifier = route.params['site'];

  if (webIdentifier != null && webIdentifier != '')
    webSvc.registerWebsite(webIdentifier);
  else
    webSvc.registerWebsite('');

  return webSvc.execute();
}


