import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {CartLocalStorageModel} from "../models/CartLocalStorageModel";
import {WebsiteService} from "./website.service";
import {WebsiteModel} from "../models/WebsiteModel";
import {isStringNotEmptyGuid} from "../utils/Utils";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private bsLocalStorage = new BehaviorSubject<CartLocalStorageModel>(new CartLocalStorageModel());
  public current$ = this.bsLocalStorage.asObservable();

  private website$: Observable<WebsiteModel>;
  private sub = new Subscription();

  constructor(private webSvc: WebsiteService) {
    this.website$ = this.webSvc.website$;

    this.init();
  }

  public getCurrentLS() {
    return this.bsLocalStorage.value;
  }

  public saveCartId(cartId: string, locationId: string) {
    const lsO = new CartLocalStorageModel(this.getCurrentLS());

    lsO.items[locationId] = cartId;

    this.saveToLocal(lsO);
  }

  saveOrderType(cartLocal: CartLocalStorageModel) {
    const lsO = new CartLocalStorageModel(this.bsLocalStorage.value);
    // lsO.orderType = cartLocal.orderType;
    // lsO.orderDate = cartLocal.orderDate;
    // lsO.isAsap = cartLocal.isAsap;

    this.saveToLocal(lsO);
  }

  saveLocation(locationId: string) {
    const lsO = new CartLocalStorageModel(this.bsLocalStorage.value);
    lsO.selectedLocationId = locationId;

    this.saveToLocal(lsO);
  }

  private saveToLocal(cartLocal: CartLocalStorageModel) {
    const w = this.webSvc.getCurrentWebsite();
    if (isStringNotEmptyGuid(w.id)) {
      cartLocal.id = w.id;
      localStorage.setItem(w.id, JSON.stringify(cartLocal));
      this.bsLocalStorage.next(cartLocal);
    }
  }

  private init() {
    this.sub.add(
      this.website$.subscribe(data => {
        if (isStringNotEmptyGuid(data.id)) {
          let ls = localStorage.getItem(data.id);
          let lsO = new CartLocalStorageModel();
          if (ls != null) {
            lsO = new CartLocalStorageModel(JSON.parse(ls));
          }
          lsO.id = data.id;
          // lsO.orderDate = new Date();
          this.bsLocalStorage.next(lsO);
        }
      })
    );
  }
}
