import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {faShoppingCart} from "@fortawesome/pro-light-svg-icons";
import {ProductOrderModel} from "../../models/ProductOrderModel";
import {ProductModifierItemOrderModel} from "../../models/ProductModifierItemOrderModel";
import {ProductService} from "../../services/product.service";
import {OrderCartItemModel} from "../../models/orders/OrderCartItemModel";
import {OrderCartItemModifierModel} from "../../models/orders/OrderCartItemModifierModel";
import {ProductModifierGroupOrderModel} from "../../models/ProductModifierGroupOrderModel";
import {CartService} from "../../services/cart.service";
import {isStringNotEmptyOrWhitespace} from "../../utils/Utils";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-menu-item-dialog',
  templateUrl: './menu-item-dialog.component.html',
  styleUrls: ['./menu-item-dialog.component.scss']
})
export class MenuItemDialogComponent implements OnInit {
  public faCart = faShoppingCart;
  // public orderItem: any = {quantity: 1};
  public product: ProductOrderModel;
  protected readonly isStringNotEmptyOrWhitespace = isStringNotEmptyOrWhitespace;

  constructor(public dialogRef: MatDialogRef<MenuItemDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: OrderCartItemModel,
              private prodSvc: ProductService, private ref: ChangeDetectorRef,
              private cartSvc: CartService, private snackBar: MatSnackBar) {
    this.product = new ProductOrderModel(this.prodSvc.findByProdToken(this.data.token));
  }

  ngOnInit(): void {
    this.processData();
  }

  getOrderTotal(): number {
    const orderData = new OrderCartItemModel(this.data);
    orderData.modifiers = [];

    let singlePrice = this.product?.price.price ?? 0;

    if (this.product != null) {
      for (let group of this.product.modifierGroups) {
        for (let modifier of group.items) {
          if (modifier.selected) {
            let orderModifier = new OrderCartItemModifierModel({
              groupToken: group.token,
              itemToken: modifier.token,
              quantity: modifier.quantity,
              price: modifier.price,
              name: modifier.name
            });
            orderData.modifiers.push(orderModifier);
          }
        }
      }
      if (orderData.modifiers.length > 0)
        for (let modifier of orderData.modifiers) {
          singlePrice += modifier.getTotal();
        }
    }

    return this.data.quantity * singlePrice;

  }

  incQuantity() {
    if (this.data.quantity != null)
      this.data.quantity++;
    else
      this.data.quantity = 1;
  }

  decQuantity() {
    if (this.data.quantity > 1)
      this.data.quantity--;
  }

  selectItem(modifierItem: ProductModifierItemOrderModel, evt: any) {
    modifierItem.quantity = evt ? 1 : 0;
    modifierItem.selected = evt;
  }

  addToCart() {
    const orderData = new OrderCartItemModel(this.data);
    orderData.modifiers = [];

    if (!this.validateOrder()) {
      return;
    }

    if (this.product != null) {
      orderData.name = this.product.name;

      if (this.product.modifierGroups != null && this.product.modifierGroups.length > 0)
        for (let group of this.product.modifierGroups) {
          for (let modifier of group.items) {
            if (modifier.selected) {
              let orderModifier = new OrderCartItemModifierModel({
                groupToken: group.token,
                itemToken: modifier.token,
                quantity: modifier.quantity,
                price: modifier.price,
                name: modifier.name
              });
              orderData.modifiers.push(orderModifier);
            }
          }
        }
      orderData.totalPrice = orderData.getTotal();
    }
    // this.cartSvc.addToCart(orderData);
    this.dialogRef.close(orderData);
  }

  selectSingleItem(modifierGroup: ProductModifierGroupOrderModel, evt: any) {
    if (modifierGroup == null || modifierGroup.items == null || modifierGroup.items.length === 0)
      return;
    for (let item of modifierGroup.items) {
      if (item.token === evt) {
        item.selected = true;
        item.quantity = 1;
      } else {
        item.selected = false;
        item.quantity = 0;
      }
    }
    this.ref.detectChanges();
  }

  private processData() {
    if (this.data.modifiers != null && this.data.modifiers.length > 0 && this.product != null && this.product.modifierGroups != null
      && this.product.modifierGroups.length > 0) {
      for (let mod of this.data.modifiers) {
        for (let group of this.product.modifierGroups) {
          if (mod.groupToken !== group.token)
            continue;

          for (let item of group.items) {
            if (item.token === mod.itemToken) {
              item.quantity = mod.quantity;
              item.selected = true;
              if (!group.isCheckbox)
                group.selectedItem = item.token;
            }
          }
        }
      }
    }
  }

  private validateOrder() {
    if (this.product == null)
      return false;

    if (this.product.modifierGroups.length == 0)
      return true;

    for (let group of this.product.modifierGroups) {
      let groupSelected = 0;
      let groupMin = group.minItems ?? 0;

      if (group.items.length == 0)
        continue;
      for (let item of group.items) {
        if (item.selected)
          groupSelected+= item.quantity;
      }

      if (groupSelected < groupMin) {
        this.snackBar.open(`Please select at least ${groupMin} item(s) from ${group.label}`, 'ERROR', {duration: 3500, politeness: 'assertive', verticalPosition: 'top'});
        return false;
      }
    }

    return true;
  }
}
