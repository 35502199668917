import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {IconData} from "../models/interfaces/IconData";

@Injectable({
  providedIn: 'root'
})
export class CustomIconsService {
  public icons: IconData[] = [
    {name: 'Chicken', value: 'chicken', path: '../../../assets/icons/014-chicken.svg'},
    {name: 'Cow', value: 'cow', path: '../../../assets/icons/013-cow.svg'},
    {name: 'Halal Black', value: 'halal', path: '../../../assets/icons/003-halal-sign.svg'},
    {name: 'Halal Alternate', value: 'halal-alt', path: '../../../assets/icons/010-halal-sign-1.svg'},
    {name: 'Kosher Outline', value: 'kosher', path: '../../../assets/icons/001-letter-k.svg'},
    {name: 'Kosher Blue', value: 'kosher-alt', path: '../../../assets/icons/011-k-1.svg'},
    {name: 'Kosher Black', value: 'kosher-alt-2', path: '../../../assets/icons/002-k.svg'},
    {name: 'Lamb', value: 'lamb', path: '../../../assets/icons/012-lamb.svg'},
    {name: 'Pig', value: 'pig', path: '../../../assets/icons/015-pig.svg'},
    {name: 'Shrimp', value: 'shrimp', path: '../../../assets/icons/017-shrimp.svg'},
    {name: 'Spicy 1', value: 'spicy-1', path: '../../../assets/icons/004-jalapeno.svg'},
    {name: 'Spicy 2', value: 'spicy-2', path: '../../../assets/icons/005-jalapeno-1.svg'},
    {name: 'Spicy 3', value: 'spicy-3', path: '../../../assets/icons/008-chili.svg'},
    {name: 'Fish Alt', value: 'fish-2', path: '../../../assets/icons/016-fish.svg'},
    {name: 'Fish', value: 'fish-1', path: '../../../assets/icons/009-fish.svg'},
    {name: 'Vegan 1', value: 'vegan', path: '../../../assets/icons/006-vegan.svg'},
    {name: 'Vegan 2', value: 'vegan-alt', path: '../../../assets/icons/007-vegan-1.svg'}
  ];
  private isInit = false;

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    this.init();
  }

  public init() {
    if (this.isInit) {
      return;
    }
    this.isInit = true;
    for (let icon of this.icons) {
      this.matIconRegistry.addSvgIcon(icon.value, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    }
  }
}
