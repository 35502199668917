import {BaseModel} from "./BaseModel";
import {isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class BaseModelWithName extends BaseModel {
  public name: string = '';

  constructor(data: any = null) {
    super(data);

    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.name))
      this.name = data.name;
  }
}

