<div mat-dialog-title>
  <div *ngIf="data" class="d-flex justify-content-between">
    {{data.friendlyName}}
    <button mat-dialog-close="false" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div class="d-flex w-100 align-items-center justify-content-between">
    <a *ngIf="website?.getSetting('FOOTER.LINKS.FACEBOOK')" [href]="website.getSetting('FOOTER.LINKS.FACEBOOK')" mat-icon-button>
      <fa-icon [icon]="faFacebookSquare"></fa-icon>
    </a>
    <a *ngIf="website?.getSetting('FOOTER.LINKS.TWITTER')" [href]="website.getSetting('FOOTER.LINKS.TWITTER')" mat-icon-button>
      <fa-icon [icon]="faTwitter"></fa-icon>
    </a>
    <a *ngIf="website?.getSetting('FOOTER.LINKS.INSTAGRAM')" [href]="website.getSetting('FOOTER.LINKS.INSTAGRAM')" mat-icon-button>
      <fa-icon [icon]="faInstagram"></fa-icon>
    </a>
    <div class="d-inline-block">
      <app-location-availability [location]="data"></app-location-availability>
    </div>
  </div>

  <mat-list *ngIf="data.availabilities.getAvailability(EnumWebsiteModules.OnlineOrder) as dayModule;">
    <mat-list-item>
      <div class="d-flex w-100 justify-content-between align-items-center border-bottom border-1">
        <span>Sunday</span>
        <span>{{dayModule.sunday.getTimeStrings()}}</span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="d-flex w-100 justify-content-between align-items-center border-bottom border-1">
        <span>Monday</span>
        <span>{{dayModule.monday.getTimeStrings()}}</span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="d-flex w-100 justify-content-between align-items-center border-bottom border-1">
        <span>Tuesday</span>
        <span>{{dayModule.tuesday.getTimeStrings()}}</span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="d-flex w-100 justify-content-between align-items-center border-bottom border-1">
        <span>Wednesday</span>
        <span>{{dayModule.wednesday.getTimeStrings()}}</span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="d-flex w-100 justify-content-between align-items-center border-bottom border-1">
        <span>Thursday</span>
        <span>{{dayModule.thursday.getTimeStrings()}}</span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="d-flex w-100 justify-content-between align-items-center border-bottom border-1">
        <span>Friday</span>
        <span>{{dayModule.friday.getTimeStrings()}}</span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="d-flex w-100 justify-content-between align-items-center border-bottom border-1">
        <span>Saturday</span>
        <span>{{dayModule.saturday.getTimeStrings()}}</span>
      </div>
    </mat-list-item>
  </mat-list>

</mat-dialog-content>
