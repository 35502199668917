import {OrderItemPriceModel} from "./OrderItemPriceModel";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../utils/Utils";
import {INutritionalInfo} from "./interfaces/INutritionalInfo";


export class ProductModifierItemOrderModel  implements INutritionalInfo {
  public name = '';
  public token = '';
  public sortOrder = 0;
  public price: OrderItemPriceModel = new OrderItemPriceModel();
  public selected: boolean = false;
  public quantity: number = 0;
  public labels: string[] = [];
  public description: string = '';
  public protein: number = 0;
  public carbs: number = 0;
  public fat: number = 0;
  public calories: number = 0;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyGuid(data.token))
      this.token = data.token;
    if (isStringNotEmptyOrWhitespace(data.name))
      this.name = data.name;
    if (data.sortOrder != null && !isNaN(parseInt(data.sortOrder, 10)))
      this.sortOrder = parseInt(data.sortOrder, 10);
    if (data.price != null)
      this.price = new OrderItemPriceModel(data.price);
    if (data.labels != null && Array.isArray(data.labels) && data.labels.length > 0)
      for (let item of data.labels) this.labels.push(item);
    if (isStringNotEmptyOrWhitespace(data.description))
      this.description = data.description;
    if (data.protein != null && !isNaN(parseFloat(data.protein))) {
      this.protein = parseFloat(data.protein);
    }
    if (data.carbs != null && !isNaN(parseFloat(data.carbs))) {
      this.carbs = parseFloat(data.carbs);
    }
    if (data.fat != null && !isNaN(parseFloat(data.fat))) {
      this.fat = parseFloat(data.fat);
    }
    if (data.calories != null && !isNaN(parseFloat(data.calories))) {
      this.calories = parseFloat(data.calories);
    }
  }
}
