import {HoursAvailabilityModel} from "./HoursAvailabilityModel";
import {EnumWebsiteModules} from "../enums/EnumWebsiteModules";
import {DayAvailabilityWithTimes} from "../DayAvailabilityWithTimes";
import {
  addDays,
  addHours,
  addMinutes,
  eachMinuteOfInterval,
  endOfDay,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  startOfDay
} from "date-fns";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../../utils/Utils";

export class DailyAvailabilityModel {
  public sunday = new HoursAvailabilityModel();
  public monday = new HoursAvailabilityModel();
  public tuesday = new HoursAvailabilityModel();
  public wednesday = new HoursAvailabilityModel();
  public thursday = new HoursAvailabilityModel();
  public friday = new HoursAvailabilityModel();
  public saturday = new HoursAvailabilityModel();
  public moduleRequired: EnumWebsiteModules = EnumWebsiteModules.OnlineOrder;
  public label: string = '';


  constructor(data: any = null) {
    if (data == null)
      return;

    if (data.sunday != null)
      this.sunday = new HoursAvailabilityModel(data.sunday);
    if (data.monday != null)
      this.monday = new HoursAvailabilityModel(data.monday);
    if (data.tuesday != null)
      this.tuesday = new HoursAvailabilityModel(data.tuesday);
    if (data.wednesday != null)
      this.wednesday = new HoursAvailabilityModel(data.wednesday);
    if (data.thursday != null)
      this.thursday = new HoursAvailabilityModel(data.thursday);
    if (data.friday != null)
      this.friday = new HoursAvailabilityModel(data.friday);
    if (data.saturday != null)
      this.saturday = new HoursAvailabilityModel(data.saturday);
    if (data.moduleRequired != null && !isNaN(parseInt(data.moduleRequired)))
      this.moduleRequired = parseInt(data.moduleRequired);
    if (isStringNotEmptyOrWhitespace(data.label))
      this.label = data.label;
  }

  public getNextDaysAvailable(countDays: number, start: Date = new Date(), leadTime: number = 15): DayAvailabilityWithTimes[] {
    const listDays: DayAvailabilityWithTimes[] = [];
    const dtNow = addMinutes(new Date(), leadTime);

    // console.log('label', this.label);
    // console.log('dtNow', dtNow, this.moduleRequired);

    let isOpen1Day = false;
    let daysProcessed = 0;
    let counterSkipped = 0;
    let dayAvailable: HoursAvailabilityModel;

    for (let i = 0; i < countDays && listDays.length <= countDays; i++) {
      daysProcessed++;
      if (daysProcessed > 8 && !isOpen1Day)
        break;
      let dayInLoop = addDays(start, i + counterSkipped);
      let dayIndex = getDay(dayInLoop);
      dayAvailable = this.getDayAvailable(dayIndex);
      if (!dayAvailable.isOpen) {
        counterSkipped++;
        i--;
        continue;
      }
      isOpen1Day = true;
      let dayInList: Nullable<DayAvailabilityWithTimes> = this.findOrAddDayInInterval(listDays, dayInLoop, countDays);
      if (dayInList == null)
        break;

      for (let time of dayAvailable.times) {
        let hasNextDayIncluded = false;
        let startDate = startOfDay(dayInLoop);
        let endDate = startOfDay(dayInLoop);

        if (time.startHour != null)
          startDate = addHours(startDate, time.startHour);
        if (time.startMinutes != null)
          startDate = addMinutes(startDate, time.startMinutes);


        if (time.endHour != null)
          endDate = addHours(endDate, time.endHour);
        if (time.endMinutes != null)
          endDate = addMinutes(endDate, time.endMinutes);

        // console.log('startDate', startDate);
        // console.log('endDate', endDate);

        if (isBefore(endDate, startDate)) {
          endDate = addDays(endDate, 1);
          hasNextDayIncluded = true;
        }

        if (isToday(startDate) && isBefore(dtNow, startDate)) {
          let step = 15;
          while (step < 60 && step < leadTime)
            step += 15;
          startDate = addMinutes(startDate, step);
        }

        if (!hasNextDayIncluded) {
          dayInList.times = dayInList.times.concat(eachMinuteOfInterval({
            start: startDate,
            end: endDate
          }, {step: 15}));

        } else {
          let endOfStartDate = endOfDay(startDate);
          let startOfEndDate = startOfDay(endDate);
          dayInList.times = dayInList.times.concat(eachMinuteOfInterval({
            start: startDate,
            end: endOfStartDate
          }, {step: 15}));

          if (isToday(endOfStartDate)) {
            for (let k = 0; k < dayInList.times.length; k++) {
              const timeInList = dayInList.times[k];
              if (isBefore(timeInList, dtNow)) {
                dayInList.times.splice(k--, 1);
              }
            }
          }

          if (isAfter(endDate, startOfEndDate)) {
            const nextDay = this.findOrAddDayInInterval(listDays, endDate, countDays);
            if (nextDay != null) {
              nextDay.times = nextDay.times.concat(eachMinuteOfInterval({
                start: startOfEndDate,
                end: endDate
              }, {step: 15}));
            }
          }
        }
      }

    }
    return listDays;
  }

  // }
  isOpen(dateAndTime: Date) {
    const dayOfWeek = getDay(dateAndTime);

    switch (dayOfWeek) {
      case 0:
        return this.sunday.isOpenAtTime(dateAndTime);
      case 1:
        return this.monday.isOpenAtTime(dateAndTime);
      case 2:
        return this.tuesday.isOpenAtTime(dateAndTime);
      case 3:
        return this.wednesday.isOpenAtTime(dateAndTime);
      case 4:
        return this.thursday.isOpenAtTime(dateAndTime);
      case 5:
        return this.friday.isOpenAtTime(dateAndTime);
      case 6:
        return this.saturday.isOpenAtTime(dateAndTime);
    }
    return false;
  }

  getTime(dtNow: Date, isEnd: boolean) {
    const dayIndex = getDay(dtNow);
    const dayAvailable = this.getDayAvailable(dayIndex);

    if (!dayAvailable.isOpen || dayAvailable.times.length == 0)
      return '';

    return isEnd ? dayAvailable.times[dayAvailable.times.length - 1].getTimeStringEnd() : dayAvailable.times[0].getTimeStringStart();
  }

  // findDay(day: EnumDays): HoursAvailabilityModel {
  //   for (let item of this.days) {
  //     if (item.day == day) {
  //       return item;
  //     }
  //   }
  //
  //   return new HoursAvailabilityModel({day: day});

  getToday(): HoursAvailabilityModel {
    const dayIndex = getDay(new Date());
    return this.getDayAvailable(dayIndex);
  }

  private findOrAddDayInInterval(list: DayAvailabilityWithTimes[], day: Date, maxItems: number): Nullable<DayAvailabilityWithTimes> {
    for (let dayAvailable of list)
      if (isSameDay(day, dayAvailable.date))
        return dayAvailable;
    if (list.length < maxItems) {
      const dayToAdd = new DayAvailabilityWithTimes(day, []);
      list.push(dayToAdd);
      return dayToAdd;
    }
    return null;
  }

  private getDayAvailable(dayIndex: 0 | 1 | 2 | 3 | 4 | 5 | 6) {
    switch (dayIndex) {
      case 0: {
        return this.sunday;
      }
      case 1: {
        return this.monday;
      }
      case 2: {
        return this.tuesday;
      }
      case 3: {
        return this.wednesday;
      }
      case 4: {
        return this.thursday;
      }
      case 5: {
        return this.friday;
      }
      case 6: {
        return this.saturday;
      }
    }
  }
}
