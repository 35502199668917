import {isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class SettingItemModel {
  public stringId?: string;
  public value?: string;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.stringId))
      this.stringId = data.stringId;
    if (isStringNotEmptyOrWhitespace(data.value))
      this.value = data.value;
  }
}
