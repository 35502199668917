import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Injectable({
  providedIn: 'root'
})
export class LayoutService implements OnDestroy {
  // private bsLayoutHandset = new BehaviorSubject<boolean>(false);
  // public readonly isHandset$ = this.bsLayoutHandset.asObservable();
  private bsLayoutSmall = new BehaviorSubject<boolean>(false);
  public readonly isSmall$ = this.bsLayoutSmall.asObservable();
  private bsLayoutXSmall = new BehaviorSubject<boolean>(false);
  public readonly isXSmall$ = this.bsLayoutXSmall.asObservable();

  private sub = new Subscription();

  constructor(private breakpointObserver: BreakpointObserver) {
    // this.sub.add(this.breakpointObserver.observe(Breakpoints.Web).pipe(map(result => !result.matches)).subscribe(data => this.bsLayoutHandset.next(data)));
    this.sub.add(this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(data => {
      if (this.bsLayoutSmall.value !== data.matches)
        this.bsLayoutSmall.next(data.matches);
      if (data.matches) {
        const isXSmall = data.breakpoints[Breakpoints.XSmall];
        if (this.bsLayoutXSmall.value !== isXSmall)
          this.bsLayoutXSmall.next(isXSmall);
      } else if (this.bsLayoutXSmall.value)
        this.bsLayoutXSmall.next(false);
    }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
