import {Component, Input, OnInit} from '@angular/core';
import {LocationService} from "../../services/location.service";
import {LocationModel} from "../../models/LocationModel";
import {MatDialog} from "@angular/material/dialog";
import {LocationInfoDialogComponent} from "../../dialogs/location-info-dialog/location-info-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";

import {isStringNotEmptyGuid} from "../../utils/Utils";

@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.scss']
})
export class LocationInfoComponent implements OnInit {

  @Input() public location!: LocationModel;

  // public faPen = faPen;
  protected readonly isStringNotEmptyGuid = isStringNotEmptyGuid;

  constructor(private locSvc: LocationService, private dialog: MatDialog, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {

  }

  openMoreInfo() {
    this.dialog.open(LocationInfoDialogComponent, {
      data: this.location,
      width: '470px'
    });
  }

  selectLocation() {
    this.router.navigate(['..', '..'], {relativeTo: this.route});
  }
}
