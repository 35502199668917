import {HoursAvailabilityTimeModel} from "./HoursAvailabilityTimeModel";
import {EnumDays} from "../enums/EnumDays";

export class HoursAvailabilityModel {
  // public day: EnumDays = EnumDays.Sunday;

  public isOpen = false;
  public times: HoursAvailabilityTimeModel[] = [];

  constructor(data: any = null) {
    if (data == null)
      return;

    if (data.isOpen != null && data.isOpen)
      this.isOpen = data.isOpen;
    if (data.times != null && Array.isArray(data.times) && data.times.length > 0)
      for (let time of data.times) this.times.push(new HoursAvailabilityTimeModel(time));
  }

  isOpenAtTime(dateAndTime: Date) {
    if (!this.isOpen)
      return false;

    for (let time of this.times)
    {
      if (time.isOpenAtTime(dateAndTime))
        return true;
    }

    return false;
  }

  getTimeStrings() {
    if (!this.isOpen)
      return 'Closed';

    const times :string[]=[];
    for (let time of this.times)
      times.push(time.getTimeString());
    return times.join(',');
  }
}
