import {DailyAvailabilityModel} from "./DailyAvailabilityModel";
import {EnumWebsiteModules} from "../enums/EnumWebsiteModules";

export class LocationAvailabilityModel {
  public availabilityList: DailyAvailabilityModel[] = [];

  constructor(data: any = null) {
    if (data == null)
      return;
    if (data.availabilityList != null && Array.isArray(data.availabilityList) && data.availabilityList.length > 0)
      for (let item of data.availabilityList) this.availabilityList.push(new DailyAvailabilityModel(item));
  }

  public getAvailability(module: EnumWebsiteModules): Nullable<DailyAvailabilityModel> {
    for (let availability of this.availabilityList) {
      if (availability.moduleRequired === module)
        return availability;
    }
    return null;
  }

  public isOpen(module: EnumWebsiteModules, dtNow: Date) {
    const moduleAvailability = this.getAvailability(module);
    if (moduleAvailability == null)
      return false;

    return moduleAvailability.isOpen(dtNow);
  }
}
