export class OrderTipModel {
  public tip: Nullable<number> = 0;
  public tipPercent: Nullable<number> = 0;
  public isCustom = true;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (data.isCustom)
      this.isCustom = true;
    if (data.tip != null && parseFloat(data.tip))
      this.tip = data.tip;
    if (data.tipPercent != null && !isNaN(parseFloat(data.tipPercent)))
      this.tipPercent = parseFloat(data.tipPercent);
  }
}
