import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {HttpService} from "./base/http.service";
import {isStringNotEmptyGuid} from "../utils/Utils";
import {map} from "rxjs/operators";
import {ProductOrderModel} from "../models/ProductOrderModel";
import {WebsiteService} from "./website.service";
import {ApiEndpoints} from "../utils/ApiEndpoints";
import {ProductMenuContainerItemModel} from "../models/ProductMenuContainerItemModel";

@Injectable({
  providedIn: 'root'
})
export class ProductService implements OnDestroy {

  private bsMenus = new BehaviorSubject<ProductMenuContainerItemModel>(new ProductMenuContainerItemModel());
  public menus$ = this.bsMenus.asObservable();
  private bsItemKeyCache = new BehaviorSubject<string>('');

  private sub = new Subscription();

  constructor(private http: HttpService, private webSvc: WebsiteService) {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  executeByToken(token: string, dtNow: Nullable<number>) {
    const web = this.webSvc.getCurrentWebsite();
    const key = dtNow == null ? `${web.id}::${token}` : `${web.id}::${token}.${dtNow}`;

    if (this.bsItemKeyCache.value === key) {
      return;
    }

    this.bsMenus.next(new ProductMenuContainerItemModel());
    this.bsItemKeyCache.next(key);

    this.sub.add(
      this.http.get<ProductMenuContainerItemModel>(`${ApiEndpoints.menuItemsGet}-${key}`).pipe(map(data => {
        return new ProductMenuContainerItemModel(data);
        // const list: ProductMenuOrderModel[] = [];
        // if (data != null && Array.isArray(data) && data.length > 0) {
        //   for (let item of data)
        //     list.push(new ProductMenuOrderModel(item));
        // }
        // return list.sort((a, b) => a.sortOrder - b.sortOrder);
      })).subscribe(data => {
        this.bsMenus.next(data);
      })
    );
  }

  findByProdToken(id: string): Nullable<ProductOrderModel> {
    if (isStringNotEmptyGuid(id)) {
      var menus = this.bsMenus.value.menus;
      if (menus != null && menus.length > 0) {
        for (let menu of menus) {
          let product = menu.findProduct(id);
          if (product != null)
            return product;
        }
      }
    }
    return null;
  }
}
