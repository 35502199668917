import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Router} from "@angular/router";
import {WebsiteService} from "./services/website.service";
import {FaConfig} from "@fortawesome/angular-fontawesome";
import {CustomIconsService} from "./services/custom-icons.service";
import {LoadingService} from "./services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SwiftServe Orders UI';

  constructor(private websiteSvc: WebsiteService, public router: Router, faConfig: FaConfig, private iconsSvc: CustomIconsService, public loadingSvc: LoadingService) {
    faConfig.defaultPrefix = 'far';
    this.iconsSvc.init();
  }
}
