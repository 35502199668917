<div class="px-2 pt-3">
  <div class="d-flex justify-content-start align-items-center flex-grow-1 position-relative">
    <div class="text-center flex-grow-1 d-flex flex-column">
      <span class="mat-headline-4 mb-0">{{ location.friendlyName }}</span>
      <span class="">{{ location.street1 }}, @if (!!location.street2) {
        {{ location.street2 }},
      } {{ location.city }}, {{ location.state }} {{ location.zip }}</span>
    </div>
    <button mat-dialog-close="false" mat-icon-button *ngIf="!disableClose" class="close-button-absolute">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-body px-3 py-3">
  <form [formGroup]="generalForm" autocomplete="off" name="form">
    <div class="text-center">
      <mat-button-toggle-group formControlName="isGuestUser" appearance="standard" [hideSingleSelectionIndicator]="true" [hideMultipleSelectionIndicator]="true"
                               multiple="false" name="isGuestUser" class="mt-4 mb-2  mat-button-toggle-blue" style="width: 98%">
        <mat-button-toggle [disabled]="!website.hasModule(EnumWebsiteModules.OnlineOrder)" [value]="true" class="w-100">Guest
        </mat-button-toggle>
        <mat-button-toggle [disabled]="!website.hasModule(EnumWebsiteModules.Delivery)" [value]="false" class="w-100">Returning User
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div *ngIf="generalForm.controls['isGuestUser'].hasError('auth')" class="text-danger">User is not yet
        authenticated
      </div>
    </div>

    @if (generalForm.controls['isGuestUser'].value === false) {
      <app-auth (isAuth)="updateAuth($event)"></app-auth>
    }
    <mat-divider class="my-3"></mat-divider>
    <mat-button-toggle-group formControlName="orderType" (change)="changeDeliveryType($event)" appearance="standard"
                             multiple="false" name="orderType" [hideSingleSelectionIndicator]="true" [hideMultipleSelectionIndicator]="true"
                             style="width: 98%" class="mat-button-toggle-green">
      <mat-button-toggle [disabled]="!website.hasModule(EnumWebsiteModules.OnlineOrder)" [value]="EnumOrderType.PickUp" class="w-100">{{ getOrderTypeText(EnumOrderType.PickUp) }}
      </mat-button-toggle>
      <mat-button-toggle [disabled]="!website.hasModule(EnumWebsiteModules.Delivery)" [value]="EnumOrderType.Delivery" class="w-100">{{ getOrderTypeText(EnumOrderType.Delivery) }}
      </mat-button-toggle>
    </mat-button-toggle-group>

    @if (generalForm.controls['orderType'].value == EnumOrderType.Delivery) {
      <div class="my-3 position-relative">
        <div class="overlay" *ngIf="isLoadingAddress">
          <mat-spinner class="spinner" color="primary"></mat-spinner>
        </div>
        <div>Saved addresses:</div>
        <mat-radio-group formControlName="selectedAddressId" name="selectedAddressId" required
                         class="d-flex flex-column">
          @for (adr of data.addresses; track adr.id; ) {
            <div class="d-flex flex-row align-items-center justify-content-between">
              <mat-radio-button [value]="adr.id" [disabled]="!adr.isValid">
                {{ adr.street1 }},
                @if (isStringNotEmptyOrWhitespace(adr.street2)) {
                  {{ adr.street2 }},<br/>
                }
                {{ adr.city }}, {{ adr.state }} {{ adr.zip }}
                @if (!adr.isValid) {
                  <div class="text-danger">Address is outside of delivery zone</div>
                }
              </mat-radio-button>
              <button mat-icon-button (click)="editAddress(adr)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>

          }
          <mat-radio-button [value]="guidEmpty">New Address</mat-radio-button>
        </mat-radio-group>

        <div *ngIf="generalForm.controls['selectedAddressId'].value == guidEmpty">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Deliver to</mat-label>
            <input type="text" name="address" formControlName="addressHint" matInput [matAutocomplete]="autoComp"
                   required
                   placeholder="Enter your delivery address">
            <mat-autocomplete #autoComp='matAutocomplete' (optionSelected)="selectAddress($event)">
              <mat-option *ngFor="let option of addressHints$ | async" [value]="option.id">
                {{ option.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    }
    <mat-divider class="my-3"></mat-divider>

    <mat-button-toggle-group formControlName="isAsap" (change)="changeDeliveryType($event)" appearance="standard"
                             multiple="false" name="chkIsAsap" [hideSingleSelectionIndicator]="true" [hideMultipleSelectionIndicator]="true"
                             style="width: 98%" class="mat-button-toggle-red">
      <mat-button-toggle [disabled]="!isOpen() || !location.allowOrderNow" [value]="true" class="w-100">Order Now</mat-button-toggle>
      <mat-button-toggle [value]="false" class="w-100">Schedule For Later</mat-button-toggle>
    </mat-button-toggle-group>
    @if (!generalForm.controls['isAsap'].value) {
      <div class="row row-cols-2 mt-3 gx-2">
        <div class="col">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Date</mat-label>
            <mat-select formControlName="selectedDate" placeholder="Date"
                        class="w-100">
              @if (generalForm.controls['orderType'].value == EnumOrderType.Delivery) {
                @for (item of location.daysAvailableDelivery; track item) {
                  @if (item.times.length > 0) {
                    <mat-option [value]="item">{{ item.getDateString() }}</mat-option>
                  }
                }
              } @else {
                @for (item of location.daysAvailable; track item) {
                  @if (item.times.length > 0) {
                    <mat-option [value]="item">{{ item.getDateString() }}</mat-option>
                  }
                }
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Time</mat-label>
            <mat-select formControlName='selectedTime' placeholder="Time" class="w-100">
              <ng-container *ngFor="let hourMinutes of generalForm.controls['selectedDate'].value?.times">
                <mat-option *ngIf="isValidMinute(hourMinutes)"
                            [value]="getUnixTime(hourMinutes)">{{ hourMinutes|date:'hh:mm a' }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mat-elevation-z4">
  <div class="d-flex w-100 align-items-stretch">
    <button (click)="updateOrder()" [disabled]="generalForm.invalid" class="flex-grow-1" color="primary"
            mat-flat-button>
      @if (disableClose) {
        Start
      } @else {
        Update
      }
      Order
    </button>
  </div>
</mat-dialog-actions>
