import {format, isToday, isTomorrow} from "date-fns";

export class DayAvailabilityWithTimes {
  constructor(public date: Date, public times: Date[] = []) {
  }

  public getDateString() {
    if (isToday(this.date))
      return 'Today';
    if (isTomorrow(this.date))
      return 'Tomorrow';
    return format(this.date, 'eee, M/d')
  }
}

