import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {ScrollSpyService} from "../services/utils/scroll-spy.service";
import {NgScrollbar} from "ngx-scrollbar";

@Directive({
  selector: '[spyOn]'
})
export class SpyOnDirective {

  @Input() activeClass!: string;
  @Input() spyOn!: string;
  @Input() focusOnMe: boolean = false;
  @Input() focusScrollbar!: NgScrollbar;
  private isActive = false;

  constructor(private el: ElementRef, private renderer: Renderer2, private spyService: ScrollSpyService) {
  }

  ngOnInit() {
    this.spyService.activeSpyTarget.subscribe(
      (targetName) => {
        if (!this.isActive && targetName === this.spyOn) {
          this.setActive();
        } else if (this.isActive && targetName !== this.spyOn) {
          this.setInActive();
        }
      }
    );
  }

  private get htmlElement() {
    return this.el.nativeElement;
  }

  setActive() {
    this.isActive = true;
    if (this.activeClass) {
      this.renderer.addClass(this.htmlElement, this.activeClass);
    }
    if (this.focusOnMe) {
      this.el.nativeElement.focus();
    }
    if (this.focusScrollbar) {
      this.focusScrollbar.scrollToElement(this.el, {left: -150});
    }
  }

  setInActive() {
    this.isActive = false;
    if (this.activeClass) {
      this.renderer.removeClass(this.htmlElement, this.activeClass);
    }
  }

}
