import {BaseModel} from "../BaseModel";

export class OrderUserAddressModel extends BaseModel {
  public orderUserId: string = '';
  public street1: string = '';
  public street2: string = '';
  public city: string = '';
  public state: string = '';
  public zip: string = '';
  public name: string = '';
  public placeId: string = '';
  public longitude: number = 0;
  public latitude: number = 0;
  public cityId: number = 0;
  public stateId: number = 0;
  public zipId: number = 0;
  public isValid: boolean = false;
  public deliveryFee: number = 0;
  public isChanged:boolean = false;

  constructor(data: any = null) {
    super(data);
    this.fillData(data);
  }

  public fillData(data: any = null) {
    if (data == null) {
      return;
    }
    if (data.orderUserId != null) {
      this.orderUserId = data.orderUserId;
    }
    if (data.street1 != null) {
      this.street1 = data.street1;
    }
    if (data.street2 != null) {
      this.street2 = data.street2;
    }
    if (data.city != null) {
      this.city = data.city;
    }
    if (data.state != null) {
      this.state = data.state;
    }
    if (data.zip != null) {
      this.zip = data.zip;
    }
    if (data.name != null) {
      this.name = data.name;
    }
    if (data.placeId != null) {
      this.placeId = data.placeId;
    }
    if (data.longitude != null && !isNaN(parseFloat(data.longitude))) {
      this.longitude = data.longitude;
    }
    if (data.latitude != null && !isNaN(parseFloat(data.latitude))) {
      this.latitude = data.latitude;
    }
    if (data.cityId != null && !isNaN(parseInt(data.cityId, 10))) {
      this.cityId = parseInt(data.cityId, 10);
    }
    if (data.stateId != null && !isNaN(parseInt(data.stateId, 10))) {
      this.stateId = parseInt(data.stateId, 10);
    }
    if (data.zipId != null && !isNaN(parseInt(data.zipId, 10))) {
      this.zipId = parseInt(data.zipId, 10);
    }
    if (data.isValid != null) {
      this.isValid = data.isValid;
    }
    if (data.deliveryFee != null && !isNaN(parseFloat(data.deliveryFee))) {
      this.deliveryFee = parseFloat(data.deliveryFee);
    }
  }
}
