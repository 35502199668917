import {isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class TaxRateModel {
  public taxRate: number = 0;
  taxRateTypeId: number = 0;
  taxEntityName = '';
  taxRateInfo: string = '';
  taxRateTypeString = '';

  constructor(data: any = null) {
    if (data == null)
      return;
    if (data.taxRate != null && !isNaN(parseFloat(data.taxRate)))
      this.taxRate = parseFloat(data.taxRate);
    if (data.taxRateTypeId != null && !isNaN(parseInt(data.taxRateTypeId)))
      this.taxRateTypeId = parseInt(data.taxRateTypeId);
    if (isStringNotEmptyOrWhitespace(data.taxEntityName))
      this.taxEntityName = data.taxEntityName;
    if (isStringNotEmptyOrWhitespace(data.taxRateInfo))
      this.taxRateInfo = data.taxRateInfo;
    if (isStringNotEmptyOrWhitespace(data.taxRateTypeString))
      this.taxRateTypeString = data.taxRateTypeString;
  }
}
