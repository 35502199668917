import {AfterViewInit, Component, OnInit} from '@angular/core';
import {WebsiteService} from "../../services/website.service";
import {Observable} from "rxjs";
import {WebsiteModel} from "../../models/WebsiteModel";
import {LocationModel} from "../../models/LocationModel";
import {LocationService} from "../../services/location.service";
import {ActivatedRoute, Router} from "@angular/router";
import {faBuilding} from "@fortawesome/pro-duotone-svg-icons";
import {LocalStorageService} from "../../services/local-storage.service";
import {SidebarService} from "../../services/sidebar.service";

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnInit, AfterViewInit {
  public website$: Observable<WebsiteModel>;

  public fadBuilding = faBuilding;

  constructor(private webSvc: WebsiteService, private locSvc: LocationService, private router: Router, private route: ActivatedRoute,
              private lsSvc: LocalStorageService, private sidebarSvc: SidebarService) {
    this.website$ = this.webSvc.website$;
  }

  ngOnInit(): void {
    this.webSvc.execute();
  }

  ngAfterViewInit() {
    this.sidebarSvc.close();
    setTimeout(() => {
      this.webSvc.setShop(false);
    });
  }

  selectLocation(item: LocationModel) {
    this.locSvc.selectLocation(item);
    this.router.navigate(['..', 'l', item.id], {relativeTo: this.route});
  }
}
