import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from "rxjs";
import {HttpService} from "./base/http.service";
import {LocationService} from "./location.service";
import {WebsiteService} from "./website.service";
import {GeoAddressModel} from "../models/interfaces/GeoAddressModel";
import {OrderCartModel} from "../models/orders/OrderCartModel";
import {ApiEndpoints} from "../utils/ApiEndpoints";
import {CartService} from "./cart.service";
import {map} from "rxjs/operators";
import {OrderGenericResponseModel} from "../models/orders/OrderGenericResponseModel";
import {SessionStorageService} from "./session-storage.service";


@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private bsAddresses = new BehaviorSubject<GeoAddressModel[]>([]);
  public results$ = this.bsAddresses.asObservable();
  private bsAddressHint = new BehaviorSubject<string>('');

  constructor(private http: HttpService, private locSvc: LocationService, private webSvc: WebsiteService, private cartSvc: CartService, private sessionSvc: SessionStorageService) {
    // this.locSvc.location$.subscribe(data => {
    //   if (data != null) {
    //     this.loadStates();
    //   }
    // });
  }

  public searchAddress(address: string) {
    if (address == null || address.trim().length < 4) {
      return of([]);
    }
    const lastValue = this.bsAddressHint.value;
    const trimmed = address.trim();
    if (lastValue === trimmed) {
      return of([]);
    }
    this.bsAddressHint.next(trimmed);
    const locId = this.locSvc.getCurrentLocation()?.id;
    const webId = this.webSvc.getCurrentWebsite()?.id;
    const cart = this.cartSvc.getLatestCart(locId);

    const retVal = this.http.post<GeoAddressModel[]>(`${ApiEndpoints.addressSearch}-${webId}::${locId}`, {id: cart?.id, addressHint: address});

    retVal.subscribe(data => {
      this.bsAddresses.next(data);
    });

    return retVal;

    // this.http.get<GeoAddressModel[]>(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=pk.eyJ1IjoibWljaGFlbG1hcmNvIiwiYSI6ImNrbGJ5N3J3ZjBnZTkyd3BkZ3Z4cWZ2ZjQifQ.6i9XQl9B1cZ3JW3fZn3W6Q&autocomplete=true&country=US&types=address`)
    //   .subscribe(data => {
    //     this.bsAddresses.next(data);
    //   });
  }

  clear() {
    this.bsAddresses.next([]);
    this.bsAddressHint.next('');
  }

  selectPlace(placeId: string, dataCart: OrderCartModel) {
    const locId = this.locSvc.getCurrentLocation()?.id;
    const webId = this.webSvc.getCurrentWebsite()?.id;

    const retVal = this.http
      .post<OrderGenericResponseModel>(`${ApiEndpoints.addressSelect}-${webId}::${locId}`, {
        id: dataCart.id,
        placeId
      })
      .pipe(map(f => new OrderGenericResponseModel(f)));

    retVal.subscribe(data => {
      if (data != null && data.data != null) {
        if (this.sessionSvc.isAuth())
          data.data.isGuestUser = false;

        this.cartSvc.pushCart(data.data);
        dataCart.fillData(data.data);
      }
    });

    return retVal;

  }
}
