import {isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class OrderItemPriceSizeModel {
  public size = '';
  public sizeId = 0;
  public price: number = 0;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.size))
      this.size = data.size;
    if (data.sizeId != null && !isNaN(parseInt(data.sizeId, 10)))
      this.sizeId = parseInt(data.sizeId, 10);
    if (data.price != null && !isNaN(parseInt(data.price, 10)))
      this.price = parseInt(data.price, 10);
  }
}
