import {isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class OrderStatusModel {
  public id: number = 0;
  public clientLabel: string = '';
  public name: string = '';
  public websiteId: number = 0;
  public locationId: number = 0;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.clientLabel))
      this.clientLabel = data.clientLabel;
    if (isStringNotEmptyOrWhitespace(data.name))
      this.name = data.name;

    if (data.id != null && !isNaN(parseInt(data.id))) {
      this.id = parseInt(data.id, 10);
    }
    if (data.websiteId != null && !isNaN(parseInt(data.websiteId))) {
      this.websiteId = parseInt(data.websiteId, 10);
    }
    if (data.locationId != null && !isNaN(parseInt(data.locationId))) {
      this.locationId = parseInt(data.locationId, 10);
    }
  }
}
