@if (modifierItem != null && (modifierItem.protein > 0 || modifierItem.carbs > 0 || modifierItem.fat > 0 || modifierItem.calories > 0)) {
  <div class="pn-container">
    <div class="d-flex justify-content-between align-items-center flex-grow-1">
      <span><b>P</b>: {{ modifierItem.protein | number:"1.0-1" }}</span>
      <span><b>C</b>: {{ modifierItem.carbs| number:"1.0-1" }}</span>
      <span><b>F</b>: {{ modifierItem.fat| number:"1.0-1" }}</span>
      <span><b>Cal</b>: {{ modifierItem.calories| number:"1.0-1" }}</span>
    </div>
  </div>
}
