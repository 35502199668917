import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrderUserAddressModel} from "../../models/orders/OrderUserAddressModel";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../../utils/Utils";

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrl: './address-dialog.component.scss'
})
export class AddressDialogComponent implements OnInit{

  public form!: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: OrderUserAddressModel, public dialogRef: MatDialogRef<AddressDialogComponent>, private fb: FormBuilder) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      street2: new FormControl(this.data.street2, {validators: []}),
    });
  }

  protected readonly isStringNotEmptyGuid = isStringNotEmptyGuid;

  save() {
    this.data.street2 = this.form.controls['street2'].value;
    this.data.isChanged = true;

    this.dialogRef.close(this.data);
  }

  protected readonly isStringNotEmptyOrWhitespace = isStringNotEmptyOrWhitespace;
}
