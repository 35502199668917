export enum EnumAuthResponseType {
  None = -1,
  Ok = 0,
  InvalidEmail = 1,
  InvalidPhone = 2,
  UserNotFound = 3,
  BadRequest = 4,
  Error = 5,
  OtpExpired = 6,
  OtpInvalid = 7,
  OtpValid = 8,
  UserAlreadyExists = 9
}
