<div *ngIf="location!=null" class="py-4">
  <ng-container *ngIf="isStringNotEmptyGuid(location.id)">
    <mat-card appearance="outlined">
      <mat-card-content>
        <div class="align-items-stretch d-flex flex-column justify-content-center w-100">
          <div class="location-title"><span>{{location.friendlyName}}</span>
            <a class="ms-3 text-on-surface" [routerLink]="['..', '..', 'selector']" matTooltip="Select a different location" mat-icon-button>
<!--              <fa-icon [icon]="faPen" size="sm" ></fa-icon>-->
              <mat-icon>edit</mat-icon>
            </a>
          </div>
          <span class="mat-card-subtitle text-center">{{location.street1}} {{location.street2}}, {{location.city}}
            , {{location.state}} {{location.zip}}</span>
          <app-location-availability [location]="location" [showMoreInfo]="true"></app-location-availability>
        </div>
        <mat-divider class="my-3"></mat-divider>
        <app-order-type-info></app-order-type-info>
      </mat-card-content>


    </mat-card>
  </ng-container>
</div>


