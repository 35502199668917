import {isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class OrderCurbsideInfoModel {
  public isCurbsidePickup = false;
  public vehicleMake = '';
  public vehicleColor = '';

  constructor(data: any = null) {
    if (data == null)
      return;

    if (data.isCurbsidePickup)
      this.isCurbsidePickup = true;
    if (isStringNotEmptyOrWhitespace(data.vehicleColor))
      this.vehicleColor = data.vehicleColor;
    if (isStringNotEmptyOrWhitespace(data.vehicleMake))
      this.vehicleMake = data.vehicleMake;
  }
}
