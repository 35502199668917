import {Component, Input} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {ProductModifierItemOrderModel} from "../../models/ProductModifierItemOrderModel";
import {INutritionalInfo} from "../../models/interfaces/INutritionalInfo";

@Component({
  selector: 'app-product-nutritional-info',
  standalone: true,
  imports: [
    DecimalPipe
  ],
  templateUrl: './product-nutritional-info.component.html',
  styleUrl: './product-nutritional-info.component.scss'
})
export class ProductNutritionalInfoComponent {
  @Input() public modifierItem!: INutritionalInfo;
}
