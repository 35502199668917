import {OrderCartModel} from "./OrderCartModel";
import {EnumResponseStatus} from "../enums/EnumResponseStatus";

export class OrderGenericResponseModel {
  public data: Nullable<OrderCartModel> = null;
  public status: EnumResponseStatus = EnumResponseStatus.Ok;
  public message: string = '';
  public errors: string[] = [];

  constructor(data: any = null) {
    if (data == null) {
      return;
    }
    if (data.data != null) this.data = new OrderCartModel(data.data);
    if (data.status != null && !isNaN(parseInt(data.status, 10))) this.status = parseInt(data.status, 10);
    if (!!data.message) this.message = data.message;
    if (data.errors != null && Array.isArray(data.errors) && data.errors.length > 0) {
      this.errors = data.errors;
    }
  }

}
