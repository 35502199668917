import {Injectable, OnDestroy} from '@angular/core';
import {HttpService} from "./base/http.service";
import {OrderCartModelExtended} from "../models/OrderCartModelExtended";
import {asyncScheduler, BehaviorSubject, catchError, Observable, of, retry, Subscription} from "rxjs";
import {OrderResponseModel} from "../models/OrderResponseModel";
import {isStringNotEmptyGuid} from "../utils/Utils";
import {WebsiteService} from "./website.service";
import {LocationService} from "./location.service";
import {map} from "rxjs/operators";
import {EnumOrderStatus} from "../models/enums/EnumOrderStatus";
import {OrderTrackResponseModel} from "../models/OrderTrackResponseModel";
import {PaymentIntent} from "@stripe/stripe-js";
import {ApiEndpoints} from "../utils/ApiEndpoints";


@Injectable({
  providedIn: 'root'
})
export class OrderService implements OnDestroy {
  private sub = new Subscription();
  private bsOrderById = new BehaviorSubject<OrderTrackResponseModel>(new OrderTrackResponseModel());
  public readonly order$ = this.bsOrderById.asObservable();

  constructor(private http: HttpService, private webSvc: WebsiteService, private locSvc: LocationService) {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public create(data: PaymentIntent) {
    const web = this.webSvc.getCurrentWebsite();
    const loc = this.locSvc.getCurrentLocation();

    let response: Observable<OrderResponseModel> = of(new OrderResponseModel({
      status: EnumOrderStatus.Error,
      errorMessage: 'Could not contact server to create your order. Try again shortly.'
    }));

    if (isStringNotEmptyGuid(web.id) && isStringNotEmptyGuid(loc.id))
      response = this.http.post<OrderResponseModel>(`${ApiEndpoints.orderCreate}-${web.id}::${loc.id}`, data).pipe(
        map(f => new OrderResponseModel(f))
      );

    return response;
  }

  public createPop(cartExtended: OrderCartModelExtended) {
    const web = this.webSvc.getCurrentWebsite();
    const loc = this.locSvc.getCurrentLocation();

    let response: Observable<OrderResponseModel> = of(new OrderResponseModel({
      status: EnumOrderStatus.Error,
      errorMessage: 'Could not contact server to create your order. Try again shortly.'
    }));

    if (isStringNotEmptyGuid(web.id) && isStringNotEmptyGuid(loc.id))
      response = this.http.post<OrderResponseModel>(`${ApiEndpoints.orderCreatePop}-${web.id}::${loc.id}`, cartExtended).pipe(
        map(f => new OrderResponseModel(f))
      );

    return response;
  }

  public createFree(cartExtended: OrderCartModelExtended) {
    const web = this.webSvc.getCurrentWebsite();
    const loc = this.locSvc.getCurrentLocation();

    let response: Observable<OrderResponseModel> = of(new OrderResponseModel({
      status: EnumOrderStatus.Error,
      errorMessage: 'Could not contact server to create your order. Try again shortly.'
    }));

    if (isStringNotEmptyGuid(web.id) && isStringNotEmptyGuid(loc.id))
      response = this.http.post<OrderResponseModel>(`${ApiEndpoints.orderCreateFree}-${web.id}::${loc.id}`, cartExtended).pipe(
        map(f => new OrderResponseModel(f))
      );

    return response;
  }

  public findById(id: string) {
    const web = this.webSvc.getCurrentWebsite();
    if (isStringNotEmptyGuid(id) && isStringNotEmptyGuid(web.id)) {
      this.sub.add(
        this.http.get<OrderTrackResponseModel>(`${ApiEndpoints.orderTrack}-${web.id}::${id}`).pipe(
          map(f => new OrderTrackResponseModel(f))
        ).subscribe(data => {
          this.bsOrderById.next(data);
          if (data != null) {
            switch (data.status.id) {
              case EnumOrderStatus.New: {
                asyncScheduler.schedule(() => {
                  this.findById(id);
                }, 15000);
                break;
              }
              case EnumOrderStatus.InProgress:
              case EnumOrderStatus.Confirmed: {
                asyncScheduler.schedule(() => {
                  this.findById(id);
                }, 60000);
                break;
              }
              case EnumOrderStatus.ReadyForPickup: {
                asyncScheduler.schedule(() => {
                  this.findById(id);
                }, 60000);
                break;
              }
            }
          }
        })
      );
    }
  }

  getPaymentIntent(cartExtended: OrderCartModelExtended) {
    const webId = this.webSvc.getCurrentWebsite()?.id;
    const locId = this.locSvc.getCurrentLocation()?.id;

    const retObs = this.http.post<OrderResponseModel>(`${ApiEndpoints.paymentIntent}::${webId}::${locId}`, cartExtended).pipe(
      map(f => new OrderResponseModel(f)),
      retry({count: 2, delay: 500, resetOnSuccess: true})
    );

    this.sub.add(retObs.subscribe(data => {
        // console.log(data);
      }
    ));

    return retObs;
  }
}
