<div *ngIf="localCart$|async as localInfo;" class="d-flex align-items-center gap-2 {{alignmentCss}}" >
  <button (click)="openPicker(localInfo)" *ngIf="location$|async as location;" color="primary" mat-button>
    <span [ngSwitch]="localInfo.orderType">
      <ng-container *ngSwitchCase="EnumOrderType.PickUp">Pickup</ng-container>
      <ng-container *ngSwitchCase="EnumOrderType.Delivery">Delivery</ng-container>
      <ng-container *ngSwitchCase="EnumOrderType.DineIn">Dine In</ng-container>
    </span>
    <span class="order-type-button-normal mx-2">for</span>
    <span *ngIf="localInfo.orderDate == null">now (Ready in {{location.leadTimeForOrders}} - {{location.leadTimeForOrdersEnd}} Min)</span>
    <span *ngIf="localInfo.orderDate != null">{{localInfo.orderDate|date:'EEE, M/d'}}<span
      class="order-type-button-normal mx-2">at</span>{{localInfo.orderDate|date:'hh:mm a'}}</span>
    <fa-icon [icon]="faPencil" class="mx-2"></fa-icon>
  </button>
</div>
