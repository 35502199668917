import {isStringNotEmptyOrWhitespace} from "../utils/Utils";

export class OrderImageModel {
  public size = '';
  public url = '';

  constructor(data: any = null) {
    if (data == null)
      return;

    if (isStringNotEmptyOrWhitespace(data.size))
      this.size = data.size;
    if (isStringNotEmptyOrWhitespace(data.url))
      this.url = data.url;
  }
}
