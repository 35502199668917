import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {formatCurrency} from "@angular/common";
import {ProductOrderModel} from "../../models/ProductOrderModel";
import {OrderCartItemModel} from "../../models/orders/OrderCartItemModel";
import {CartService} from "../../services/cart.service";
import {of} from "rxjs";

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input() product!: ProductOrderModel;

  // @Input() category!: ProductCategoryModel | null;

  constructor(public cartSvc: CartService, private modal: MatDialog) {
  }

  ngOnInit(): void {
    // this.webImageSvc.execute();
  }

  openAddToCart() {
    if (!this.product.isAvailable) {
      return;
    }
    let cartItem = new OrderCartItemModel({id: this.product.id, token: this.product.token, name: this.product.name, price: this.product.price})

    this.cartSvc.openDialog(cartItem);
  }

  getPrice(): string {
    if (this.product != null) {
      if (this.product.price != null) {
        return this.product.price.price == null || this.product.price.price == 0 ? 'FREE' : formatCurrency(this.product.price.price, 'en-US', '$');
      }
    }

    return 'FREE';
  }

  protected readonly of = of;
}
