import {addDays, addHours, addMinutes, format, isAfter, isBefore, startOfDay, startOfToday} from "date-fns";

export class HoursAvailabilityTimeModel {
  public startHour: Nullable<number> = null;
  public startMinutes: Nullable<number> = null;
  public endHour: Nullable<number> = null;
  public endMinutes: Nullable<number> = null;

  constructor(data: any = null) {
    if (data == null)
      return;

    if (data.startHour != null && !isNaN(parseInt(data.startHour, 10))) {
      this.startHour = parseInt(data.startHour, 10);
    }
    if (data.startMinutes != null && !isNaN(parseInt(data.startMinutes, 10))) {
      this.startMinutes = parseInt(data.startMinutes, 10);
    }
    if (data.endHour != null && !isNaN(parseInt(data.endHour, 10))) {
      this.endHour = parseInt(data.endHour, 10);
    }
    if (data.endMinutes != null && !isNaN(parseInt(data.endMinutes, 10))) {
      this.endMinutes = parseInt(data.endMinutes, 10);
    }
  }

  getTimeString() {
    let startDate = startOfToday();
    let endDate = startOfToday();

    if (this.startHour != null)
      startDate = addHours(startDate, this.startHour);
    if (this.startMinutes != null)
      startDate = addMinutes(startDate, this.startMinutes);

    if (this.endHour != null)
      endDate = addHours(endDate, this.endHour);
    if (this.endMinutes != null)
      endDate = addMinutes(endDate, this.endMinutes);

    if (isBefore(endDate, startDate))
      endDate = addDays(endDate, 1);

    return `${format(startDate, 'h:mm a')} - ${format(endDate, 'h:mm a')}`;
  }

  isOpenAtTime(dateAndTime: Date) {

    let startDate = startOfDay(dateAndTime);
    let endDate = startOfDay(dateAndTime);

    if (this.startHour != null)
      startDate = addHours(startDate, this.startHour);
    if (this.startMinutes != null)
      startDate = addMinutes(startDate, this.startMinutes);

    if (this.endHour != null)
      endDate = addHours(endDate, this.endHour);
    if (this.endMinutes != null)
      endDate = addMinutes(endDate, this.endMinutes);

    if (isBefore(endDate, startDate))
      endDate = addDays(endDate, 1);

    return isAfter(dateAndTime, startDate) && isBefore(dateAndTime, endDate);
  }

  getTimeStringEnd() {
    let endDate = startOfToday();

    if (this.endHour != null)
      endDate = addHours(endDate, this.endHour);
    if (this.endMinutes != null)
      endDate = addMinutes(endDate, this.endMinutes);

    return format(endDate, 'h:mm a');
  }

  getTimeStringStart() {
    let startDate = startOfToday();

    if (this.startHour != null)
      startDate = addHours(startDate, this.startHour);
    if (this.startMinutes != null)
      startDate = addMinutes(startDate, this.startMinutes);

    return format(startDate, 'h:mm a');
  }
}
