<mat-sidenav-container [ngClass]="isSmall? 'sidenav-small':'sidenav-big'" class="sidenav-container">
  <mat-sidenav #sidenav [mode]="(isSmall$|async) ? 'over' : 'side'" [opened]="!((isSmall$|async)==true) && (isShop$|async)==true" class="cart-side"
               fixedInViewport="true"
               position="end">
    <app-cart></app-cart>
  </mat-sidenav>
  <mat-sidenav #sidenavLeft [mode]="'over'" [opened]="false" class="nav-side"
               fixedInViewport="true"
               position="start">
    <div class="top-container">
      <span class="menu-label">Menu</span>
      <button mat-icon-button (click)="sidenavLeft.close();">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-nav-list>
      @for (item of (website$|async)?.menuItems; track item) {
        @if (item.isLocationSpecific && !item.isHidden) {
          <!--          <h3 matSubheader>{{item.label}}</h3>-->
          <mat-list-item [lines]="website.locations.length">
            <!--            <button mat-icon-button (click)="item.collapsed = !item.collapsed">-->
            <!--              <mat-icon>menu</mat-icon>-->
            <!--            </button>-->
            <a matListItemTitle class="fw-bold" (click)="item.collapsed = !item.collapsed">{{ item.label }}</a>
            <!--            @if (item.collapsed) {-->
            <mat-nav-list>
              @for (loc of website.locations; track loc) {
                <a [href]="getMenuLink(item.link, loc.identifier)" mat-list-item>{{ loc.name }}</a>
              }
            </mat-nav-list>
            <!--            }-->
          </mat-list-item>
        } @else {
          <a *ngIf="!item.isHidden" [href]="getMenuLink(item.link)" mat-list-item><span matListItemTitle class="fw-bold">{{ item.label }}</span></a>
        }
      }
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="d-flex flex-column justify-content-start align-items-stretch ">
      <mat-toolbar class="top-navigation">
        <mat-toolbar-row>
          @if (isXSmall$|async) {
            <button mat-icon-button (click)="sidenavLeft.toggle();">
              <mat-icon>menu</mat-icon>
            </button>
          }

          <span class="flex-spacer"></span>
          <ng-container *ngIf="website">
            <a *ngIf="isStringNotEmptyGuid(website.id)" class="logo-link align-self-center" href="/">
              <img [src]="website.getSetting('GENERAL.LOGO.URL')" alt="Logo" class="logo-image align-self-center"/>
            </a>
          </ng-container>

          <span class="flex-spacer"></span>
          <ng-container *ngIf="cart$|async as cartItem;">
            <a (click)="sidenav.toggle()" *ngIf="isShop$|async" class="align-self-center" mat-icon-button>
              <mat-icon [matBadgeHidden]="cartItem.getTotalItemsCount()===0" [matBadge]="cartItem.getTotalItemsCount()"
                        matBadgeColor="primary" aria-hidden="false">shopping_cart
              </mat-icon>
            </a>
          </ng-container>

        </mat-toolbar-row>
        <mat-toolbar-row class="navi-top-bar">
          <div class=" d-flex flex-wrap justify-content-center align-items-center  w-100">
            <!--      <div class=" d-flex flex-wrap justify-content-center align-items-center flex-grow-1">-->
            <ng-container *ngFor="let item of (website$|async)?.menuItems">
              <ng-container *ngIf="item.isLocationSpecific then multiMenu; else singleMenu;">
              </ng-container>

              <ng-template #singleMenu>
                <a *ngIf="!item.isHidden" [href]="getMenuLink(item.link)" class="mx-2 " mat-button>{{ item.label }}</a>
              </ng-template>

              <ng-template #multiMenu>
                <button [matMenuTriggerFor]="menu" class="mx-2" mat-button>{{ item.label }}</button>
                <mat-menu #menu="matMenu">
                  <a *ngFor="let loc of website.locations" [href]="getMenuLink(item.link, loc.identifier)" mat-menu-item>{{ loc.name }}</a>
                </mat-menu>
              </ng-template>
            </ng-container>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <!--      </div>-->
      <div class="d-flex flex-column align-items-stretch flex-grow-1">
        <div class="container-ss-lg">
          <router-outlet></router-outlet>
        </div>
        <div class="flex-grow-1"></div>
        <app-footer class="mt-3"></app-footer>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
