import {OrderItemPriceModel} from "./OrderItemPriceModel";
import {ProductModifierGroupOrderModel} from "./ProductModifierGroupOrderModel";
import {OrderImageModel} from "./OrderImageModel";
import {isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../utils/Utils";
import {BaseModelInt} from "./BaseModelInt";
import {INutritionalInfo} from "./interfaces/INutritionalInfo";

export class ProductOrderModel extends BaseModelInt implements INutritionalInfo {
  public name = '';
  public description = '';
  public token = '';
  public sortOrder = 0;
  public price: OrderItemPriceModel = new OrderItemPriceModel();
  public images: OrderImageModel[] = [];
  public modifierGroups: ProductModifierGroupOrderModel[] = [];
  public isAvailable: boolean = false;
  public labels: string[] = [];
  public protein: number = 0;
  public carbs: number = 0;
  public fat: number = 0;
  public calories: number = 0;

  constructor(data: any = null) {
    super(data);

    if (data == null)
      return;

    if (isStringNotEmptyGuid(data.token))
      this.token = data.token;
    if (isStringNotEmptyOrWhitespace(data.name))
      this.name = data.name;
    if (isStringNotEmptyOrWhitespace(data.description))
      this.description = data.description;

    if (data.sortOrder != null && !isNaN(parseInt(data.sortOrder, 10)))
      this.sortOrder = parseInt(data.sortOrder, 10);
    if (data.price != null)
      this.price = new OrderItemPriceModel(data.price);
    if (data.images != null && Array.isArray(data.images) && data.images.length > 0)
      for (let item of data.images) this.images.push(new OrderImageModel(item));
    if (data.modifierGroups != null && Array.isArray(data.modifierGroups) && data.modifierGroups.length > 0)
      for (let item of data.modifierGroups) this.modifierGroups.push(new ProductModifierGroupOrderModel(item));
    if (data.isAvailable)
      this.isAvailable = true;
    if (data.labels != null && Array.isArray(data.labels) && data.labels.length > 0) {
      for (let item of data.labels) {
        this.labels.push(item);
      }
    }
    if (data.protein != null && !isNaN(parseFloat(data.protein))) {
      this.protein = parseFloat(data.protein);
    }
    if (data.carbs != null && !isNaN(parseFloat(data.carbs))) {
      this.carbs = parseFloat(data.carbs);
    }
    if (data.fat != null && !isNaN(parseFloat(data.fat))) {
      this.fat = parseFloat(data.fat);
    }
    if (data.calories != null && !isNaN(parseFloat(data.calories))) {
      this.calories = parseFloat(data.calories);
    }
  }

  getImage(size: string, defaultUrl: string = 'assets/images/noimage.png') {
    if (this.images != null) {
      for (let item of this.images)
        if (item.size == size)
          return item.url;
      if (this.images.length > 0)
        return this.images[0].url;
    }
    return defaultUrl;
  }

  hasImage(size: string) {
    return this.images != null && this.images.length > 0;
  }

  isValid(){
    let valid = true;

    if (this.modifierGroups.length>0){
      for (let group of this.modifierGroups){
        valid = valid && group.isValid();
      }
    }

    return valid;
  }
}

