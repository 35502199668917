<ng-container *ngIf="order$|async as order;">
  <ng-container *ngIf="order != null && order.id!=null">
    <mat-toolbar class="mat-elevation-z4" color="primary">
      <!--  <mat-toolbar-row>-->
      <div class="text-header w-100">Track your order</div>
      <!--  </mat-toolbar-row>-->
    </mat-toolbar>

    <mat-progress-bar [value]="getProgressValue(order)" class="mb-4" color="accent"
                      mode="determinate"></mat-progress-bar>

    <div class="text-center mat-body">
      <h1 class="text-header">Order #{{ order.id }}</h1>
      <p class="fw-600 text-uppercase">
        Estimated carryout time:
        <ng-container *ngIf="order.orderConfirmedDate != null then hasOrderDate;else noOrderDate;">
        </ng-container>
        <ng-template #hasOrderDate>
          {{ order.orderConfirmedDate|date:'h:mm a on MMM d' }}
        </ng-template>
        <ng-template #noOrderDate>Pending...</ng-template>
      </p>
    </div>

    <mat-card appearance="raised" class="mat-elevation-z6 mb-3">
      <mat-card-header>
        <mat-stepper [linear]="false" [selectedIndex]="5" class="w-100" color="primary" headerPosition="top"
                     orientation="horizontal">
          <mat-step [completed]="order.status.id>=1" [editable]="false" label="New"></mat-step>
          <mat-step [completed]="order.status.id>=2" [editable]="false" label="Confirmed"></mat-step>
          <mat-step [completed]="order.status.id>=3" [editable]="false" label="In Progress"></mat-step>
          <mat-step [completed]="order.status.id>=4" [editable]="false" label="Pickup Ready"></mat-step>
          <mat-step [completed]="order.status.id>=5" [editable]="false" label="Picked Up"></mat-step>
          <mat-step *ngIf="order.orderType == EnumOrderType.Delivery" [completed]="order.status.id>=6"
                    [editable]="false" label="Delivered"></mat-step>
        </mat-stepper>
      </mat-card-header>
      <mat-card-content>
        <!--        <mat-card-title class="text-center mb-3">{{order.status.clientLabel}}</mat-card-title>-->
        <div class="row row-cols-1 row-cols-md-2  align-items-stretch g-3">
          <div class="col">
            <h2>{{ location?.friendlyName }}</h2>
            <table class="info-table">
              <tbody>
              <tr>
                <td class="td-icon">
                  <fa-icon [icon]="faLocation" size="xl"></fa-icon>
                </td>
                <td class="td-info">
                  {{ location?.street1 }} {{ location?.street2 }}, {{ location?.city }} {{ location?.state }} {{ location?.zip }}
                </td>
              </tr>
              <tr *ngIf="location?.contactPhone != null">
                <td class="td-icon">
                  <fa-icon [icon]="faPhone" size="xl"></fa-icon>
                </td>
                <td class="td-info">
                  {{ location?.contactPhone }}
                </td>
              </tr>
              <tr>
                <td class="td-icon">
                  <fa-icon [icon]="faKeyboard" size="xl"></fa-icon>
                </td>
                <td class="td-info">
                  Order Type: {{ order.getOrderTypeString() }}
                </td>
              </tr>
              <tr>
                <td class="td-icon">
                  <fa-icon [icon]="faThermometer" size="xl"></fa-icon>
                </td>
                <td class="td-info d-flex align-items-center gap-2">
                  Status:
                  <div class="d-inline-block">
                    <mat-chip-listbox>
                      <mat-chip-option [selected]="order.status.id==5"
                                       color="primary">{{ order.status.clientLabel }}
                      </mat-chip-option>
                    </mat-chip-listbox>
                  </div>

                </td>
              </tr>
              <!--              <tr>-->
              <!--                <td class="td-icon">-->
              <!--                  <fa-icon [icon]="faBadgeDollar" size="xl"></fa-icon>-->
              <!--                </td>-->
              <!--                <td class="td-info d-flex align-items-center gap-2">-->
              <!--                  Payment-->

              <!--                </td>-->
              <!--              </tr>-->
              </tbody>
            </table>
          </div>
          <div class="col">
            <table class="cart-table">
              <tbody>
              <tr *ngFor="let item of order.items; index as i;trackBy: orderItemTrackByFn" role="group">
                <td class="cart-item-q">{{ item.quantity }}</td>
                <td class="cart-item-n">
                  <span class="item-name">{{ item.name }}</span>
                  <ul *ngIf="item.modifiers.length > 0" class="cart-modifiers">
                    <li *ngFor="let mod of item.modifiers; last as isLast" class="cart-modifier-checkout text-muted">
                      <ng-container *ngIf="mod.quantity>1">
                        <span class="modifier-quantity">{{ mod.quantity }}</span> x
                      </ng-container>
                      {{ mod.name }}
                      <ng-container *ngIf="!isLast">,</ng-container>
                    </li>
                  </ul>
                  <div *ngIf="item.specialInstructions!=null && item.specialInstructions.length>0" class="text-muted">
                    Instruction:
                    {{ item.specialInstructions }}
                  </div>
                </td>
                <td class="cart-item-p">
                  @if (item.discountAmount > 0) {
                    <span class="text-strike">{{ item.getTotal()|currency }}</span>
                    {{ item.getDiscountedTotal()|currency }}
                  } @else {
                    {{ item.getTotal()|currency }}
                  }


                </td>
              </tr>

              <tr>
                <td class="cart-item-q">&nbsp;</td>
                <td class="cart-item-n"><span class="item-name">Subtotal
                  <br/>
                  Taxes
                  <br/>
                    Convenience Fee<br/>
                  @if (order.deliveryFee > 0) {
                    Delivery Fee<br/>
                  }
                  Tip
                  <br/>
                  Total</span>
                </td>
                <td class="cart-item-p">{{ order.amount|currency }}<br/>
                  {{ order.amountTax|currency }}<br/>
                  {{ order.amountFees|currency }}<br/>
                  @if (order.deliveryFee > 0) {
                    {{ order.deliveryFee|currency }}<br/>
                  }
                  {{ order.amountTip|currency }}<br/>
                  {{ order.amountTotal|currency }}
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-container>
