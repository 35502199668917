import {EnumPaymentProvider} from "./enums/EnumPaymentProvider";

export class OrderPaymentInfoResponseModel {
  public paymentProvider: EnumPaymentProvider = EnumPaymentProvider.PayOnPickup;
  public transactionNo: string = '';
  public transactionMessage: string = '';
  public transactionDescription: string = '';
  public transactionStatus: string = '';

  constructor(data: any = null) {
    if (data == null)
      return;
  }
}
