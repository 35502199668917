import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";
import {WebsiteModel} from "../models/WebsiteModel";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {OrderCartModel} from "../models/orders/OrderCartModel";
import {LocationModel} from "../models/LocationModel";
import {formatCurrency} from "@angular/common";
import {DEFAULT_CURRENCY_CODE} from "@angular/core";
import {EnumOrderType} from "../models/enums/EnumOrderType";
import {OrderTrackResponseModel} from "../models/OrderTrackResponseModel";


export class Utils {
  public static HandleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  static getTaxesAndFeedTooltip(cart: OrderCartModel | OrderTrackResponseModel, location: LocationModel) {
    let tooltip: string = `Estimated Tax: ${formatCurrency(cart.getTotalItemsDiscountedAmount() * location.taxRate(), 'en-US', "$")}`;
    tooltip += ` Convenience Fee: ${formatCurrency(location.customFee, 'en-US', '$')}`;
    // if (cart.orderType === EnumOrderType.Delivery) {
    //   tooltip += ` Delivery Fee: ${formatCurrency(cart.deliveryFee, 'en-US', '$')}`;
    // }

    return tooltip;
  }
}

export const guidEmpty: string = '00000000-0000-0000-0000-000000000000';

export function isStringNotEmptyGuid(value: any): boolean {
  const isStr = isString(value);
  if (isStr) {
    return value.trim().length > 0 && value !== guidEmpty;
  } else {
    return false;
  }
}

export function isStringNotEmptyOrWhitespace(value: any): boolean {
  const isStr = isString(value);
  if (isStr) {
    return value.trim().length > 0;
  } else {
    return false;
  }
}

export function isStringNotEmpty(value: any): boolean {
  const isStr = isString(value);
  if (isStr) {
    return value.length > 0;
  } else {
    return false;
  }
}

export function isString(value: any): value is string {
  return !!value && typeof value === 'string';
}

export function getMenuLinkUtil(link: string, secondLevel: Nullable<string> = null, website: Nullable<WebsiteModel> = null, sanitizer: DomSanitizer): Nullable<string> | SafeUrl {
  if (link == null || link == '')
    return null;
  if (link.startsWith('/')) {
    if (website != null) {
      let baseUrl = website.getSetting('GENERAL.HOST.URL');
      if (baseUrl != null) {
        if (baseUrl.endsWith('/'))
          baseUrl = baseUrl.substring(0, baseUrl.length - 1);
        baseUrl = baseUrl.replace("https://", "").replace("http://", "");

        const finalUrl = secondLevel != null ? `https://${baseUrl}${link}/${secondLevel}` : `https://${baseUrl}${link}`;
        return sanitizer.bypassSecurityTrustUrl(finalUrl);
      }
    }
  } else if (link.startsWith("http")) {
    return sanitizer.bypassSecurityTrustUrl(link);
  }

  return null;
}

export function valueNotEmptyGuidValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !isStringNotEmptyGuid(control.value) ? {guidEmpty: {value: control.value}} : null;
  };
}
