import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {shopResolverFn} from "./resolvers/shop.resolver";
import {MainLayoutComponent} from "./pages/main-layout/main-layout.component";
import {LocationSelectorComponent} from "./pages/location-selector/location-selector.component";
import {CheckoutComponent} from "./pages/checkout/checkout.component";
import {MainShopComponent} from "./components/main-shop/main-shop.component";
import {TrackingComponent} from "./pages/tracking/tracking.component";
import {locationResolverFn} from "./resolvers/locationResolverFn";

const routes: Routes = [{
  path: 'w/:site',
  component: MainLayoutComponent,
  resolve: {
    website: shopResolverFn
  },
  children: [
    {
      path: 'selector',
      component: LocationSelectorComponent
    },
    {
      "path": 'l/:token',
      "component": MainShopComponent,

    }, {
      path: 'checkout',
      component: CheckoutComponent
    }, {
      path: 'track-order/:token',
      component: TrackingComponent
    }, {
      path: '**',
      redirectTo: 'selector'
    }
  ]
},
  {
    path: '',
    component: MainLayoutComponent,
    resolve: {
      website: shopResolverFn
    },
    children: [
      {
        path: 'selector',
        component: LocationSelectorComponent
      },
      {
        path: 'l/:token',
        component: MainShopComponent,
        data: {
          isShop: true
        }
      }, {
        path: 'checkout',
        component: CheckoutComponent
      }, {
        path: 'track-order/:token',
        component: TrackingComponent
      }, {
        path: '**',
        redirectTo: 'selector'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
